import React,{useEffect,useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const TopFeatures =()=> {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };
      
    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
    }, []);

    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
              <h2 className="heading-h2 text-center">Our Top P2P Crypto Exchange <span className="heading-h3 bluecolor">Development Features</span></h2>
          <div className="row table-content orderflex marbot-0">
            <div className="col-md-12 col-lg-6 order2">   
              <div className="top-left" >
                <h3 className="heading-h4">KYC and AML</h3>
                <p className="pharagraph">An intuitive identity verification system accelerates the KYC process to build a base of lawful and authenticated users.
                </p>
              </div>
            </div>
            {isDesktop && (
            <div className="col-md-12 col-lg-6 right-side order1" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/p2p-new/kyc-aml.png"
                alt="KYC and AML"
                width={535}
              />
            </div>
            )}
          </div>
          <div className="row table-content orderflex marbot-0">
          {isDesktop && (
            <div className="col-md-12 col-lg-6  order1">
               <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/p2p-new/p2p-escrow-integration.png"
                alt="KYC and AML"
                width={536}
                height={313}
              />
            </div>
            )}
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h3 className="heading-h4">Escrow Integration</h3>
                <p className="pharagraph">Due to the integration of a strong escrow system, it helps users carry out transactions without any issues.</p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex marbot-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Multi-Cryptocurrency Wallet Integration</h3>
                <p className="pharagraph">The integrated crypto wallet in our P2P cryptocurrency exchange software helps your users to store, receive, and transfer any crypto assets in a hassle-free manner.</p>
              </div>
            </div>
            { isDesktop && (
            <div className="col-md-12 col-lg-6 right-side order1" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/p2p-new/multi-cryptocurrency-wallet.png"
                alt="Escrow Integration"
                width={565}
                height={313}
              />
            </div>
            )}
          </div>
          <div className="row table-content orderflex marbot-0">
            { isDesktop && (
            <div className="col-md-12 col-lg-6 order1" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/p2p-new/transparency.png"
                alt="Multi-Cryptocurrency Wallet Integration"
                width={536}
              />
            </div>
            )}
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Transparency</h3>
                <p className="pharagraph">Due to transparency and openness, more buyers and sellers are eager to trade on the Peer-to-Peer Cryptocurrency exchange platform.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Multiple Payment Methods</h3>
                <p className="pharagraph">Users will have access to a variety of payment options, which may be advantageous to those who prefer in-person transactions.
                </p>
              </div>
            </div>
            { isDesktop && (
            <div className="col-md-12 col-lg-6 right-side order1" >
              <img className='lazyload' width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/p2p-new/multi-method.png" alt="Multiple Payment Methods" />
            </div>
            )}
          </div>
        </div>
      </section>
    )
}

export default TopFeatures