import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class TechStack extends React.Component {


  render() {
    return (
      <div className='pt-100'>
      <section className="technostack mb-0">
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 text-left">
              <h5 className="heading-h2"><span className="bluecolor">Technologies Used</span> For Our P2P Crypto Exchange Development</h5>
              <p className="pharagraph">We at Coinclone use a set of advanced technologies for creating and deploying error-free P2P crypto exchange software.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                    alt="Technologies stock"
                    className='logostck'
                    width={573}
                  />
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default TechStack