import React from "react"

class RevenueStreams extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="devmethod mb-0">
        <div className="container"> 
          <h2 className="heading-h2 text-center">
            <span className="bluecolor">Revenue Streams</span> of our P2P Crypto Exchange Software
          </h2>
          <p className="text-center">
          Maximize your earnings with the versatile revenue options offered by our premier P2P crypto exchange software.
          </p>
          <div className="d-lg-flex">
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/p2p-new/listing-fees.png"
                  alt="Listing Fees image1"
                />
                <span>Listing Fees</span>
              </h3>
              <p className="pharagraph">
              Charge users a fee to list their cryptocurrencies on the exchange, enhancing the visibility and trading potential of their assets. This fee supports the platform's growth and maintenance costs.
              </p>
            </div>
            <div className="square">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/p2p-new/ransaction.png"
                  alt="Transaction Fees image1"
                />
                <span>Transaction Fees</span>
              </h3>
              <p className="pharagraph">
              Collect a percentage from each trade executed on the platform. This generates revenue with every successful transaction, providing consistent income that aligns with user activity and trade volume.
              </p>
            </div>
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/p2p-new/premium.png"
                  alt="Premium Services image1"
                />
                <span>Premium Services</span>
              </h3>
              <p className="pharagraph">
              Offer advanced features or services for a fee, such as in-depth analytics, advanced trading tools, or priority customer support. This adds value for users while creating an additional revenue channel.
              </p>
            </div>
          </div>
          <div className="d-lg-flex">
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/p2p-new/advertising.png"
                  alt="Advertising image1"
                />
                <span>Advertising</span>
              </h3>
              <p className="pharagraph">
              Monetize the platform by displaying targeted advertisements or sponsored content to your user base. This leverages the high traffic and engagement on your exchange to generate extra income.
              </p>
            </div>
            <div className="square">
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/p2p-new/withdrawal.png"
                  alt="Withdrawal Fees image1"
                />
                <span>Withdrawal Fees</span>
              </h3>
              <p className="pharagraph">
              Implement fees for withdrawing funds from the exchange. This adds a layer of revenue from user transactions and supports operational costs and platform sustainability.
              </p>
            </div>
            <div className="square" >
              <h3>
                <img
                  width={55}
                  height={55}
                  src="https://coinsclone.mo.cloudinary.net/images/p2p-new/subscription.png"
                  alt="Subscription Fees image1"
                />
                <span>Subscription Fees</span>
              </h3>
              <p className="pharagraph">
              Provide access to exclusive features, enhanced functionalities, or premium content through a subscription model. This ensures a steady, recurring revenue stream from subscribers.
              </p>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default RevenueStreams
