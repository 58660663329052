import React, { useState,useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const CoreFeatures = () => {

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };
      
    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
    }, []);

  const [activeTab, setActiveTab] = useState('tab1');

  const openTabSection = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section className="trading bgremove pt-100 mb-0" id="corefea">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h3 className="heading-h2">
            <span className="heading-h3">
              <span className="bluecolor">Core Features</span> of our
            </span>
            P2P Cryptocurrency Exchange Development
          </h3>
        </div>
        <div className="row">
          <div className="courses-details-desc">
            <ul className="nav nav-tabs tabNav nav-pills mw1030" id="myTab" role="presentation">
              <li
                role="presentation"
                className={activeTab === 'tab2' ? 'current' : ''}
                onClick={() => openTabSection('tab2')}
              >
                Dedicated Admin Panel
              </li>
              <li
                role="presentation"
                className={activeTab === 'tab10' ? 'current' : ''}
                onClick={() => openTabSection('tab10')}
              >
                Admin Profit Management
              </li>
              <li
                role="presentation"
                className={activeTab === 'tab11' ? 'current' : ''}
                onClick={() => openTabSection('tab11')}
              >
                Online/Offline Trading
              </li>
              <li
                role="presentation"
                className={activeTab === 'tab9' ? 'current' : ''}
                onClick={() => openTabSection('tab9')}
              >
                Support System
              </li>
              <li
                role="presentation"
                className={activeTab === 'tab1' ? 'current' : ''}
                onClick={() => openTabSection('tab1')}
              >
                User Panel
              </li>
            </ul>

            <div className="tab-content">
              <div id="tab2" className={`tab-pane tabs_items ${activeTab === 'tab2' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tab2' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h4 className="heading-h4">Dedicated Admin Panel</h4>
                      <p className="pharagraph">It allows admins to manage & modify trading functionalities, commission fees & other features of the platform.</p>
                    </div>
                  </div>
                  {isDesktop && (
                  <div className="col-md-6 right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/dedicated-admin-panel.png"
                      alt="Dedicated Admin Panel"
                      width={400}
                      height={275}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tab10" className={`tab-pane tabs_items ${activeTab === 'tab10' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tab10' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h4 className="heading-h4">Admin Profit Management</h4>
                      <p className="pharagraph">The transparent profit management system helps the admin to calculate and update the commissions for each trading.</p>
                    </div>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p/admin-profit-management.png"
                      alt="Admin profit management"
                      width={366}
                      height={232}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tab11" className={`tab-pane tabs_items ${activeTab === 'tab11' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tab11' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h4 className="heading-h4">Online/Offline Trading</h4>
                      <p className="pharagraph">In online trade, the buyer and seller meet online at any time, anywhere. Offline trading meets face-to-face with the buyer/seller. They meet in a public place and exchange money or any type of payment.</p>
                    </div>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/on-off.png"
                      alt="Online/Offline Trading"
                      width={310}
                      height={232}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tab9" className={`tab-pane tabs_items ${activeTab === 'tab9' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tab9' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h4 className="heading-h4">Support System</h4>
                      <p className="pharagraph">It helps your users to raise their complaints and queries directly with the admin. The admin can close the inquiry once solved.</p>
                    </div>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/support-systerm.png"
                      alt="Support System"
                      width={269}
                      height={226}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tab1" className={`tab-pane tabs_items ${activeTab === 'tab1' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tab1' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <div className="mw466">
                      <h4 className="heading-h4">User Panel</h4>
                      <p className="pharagraph">Users can buy, sell, post-trade, deposit & withdrawals, support tickets, and much more in a hassle-free manner.</p>
                    </div>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 right-side order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p/user-panel-p2p.png"
                      alt="User Panel"
                      width={400}
                      height={266}
                    />
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoreFeatures;
