import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


const BannerSection =()=> {

    return (
      <section className="banner">
        <div className="container text-center">
            <h1 className="main-heading text-center">P2P Crypto Exchange Development Company</h1>
            <p className="mb-3 text-center text-white">Unlock new horizons for your crypto exchange startup with our advanced P2P crypto exchange development services. Let us help traders experience effortless, secure, and decentralized trading across your P2P exchange platform.</p>
          
          <StaticImage
            src="https://coinsclone.mo.cloudinary.net/images/p2p-new/p2p-crypto-exchange-development.webp"
            alt="P2P Crypto Exchange Development Company"
            className='mobile-hide'
            width={810}
          />
          <div className="cta-banner justify-content-md-center">
              <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
          </div>
        </div>
      </section>
    )
}

export default BannerSection;