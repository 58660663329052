import React ,{useEffect, useState} from 'react'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  return (
    <div className='pt-100'>
    <section className="how-our mb-0">
      <div className="container">
          <h2 className="heading-h2 text-center"><span className="heading-h3 bluecolor">How Does 
          </span>
          Our P2P Crypto Exchange Work?
          </h2>
          { isDesktop && (
          <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1176.5" height={1534} viewBox="0 0 1176.5 1534">
          <defs>
            <clipPath id="clip-path">
              <path id="Path_19149" data-name="Path 19149" d="M273.323,142.118l22.9-13.222-11.8-16.7-28.334,13.362Z" transform="translate(-256.095 -112.201)" fill="none" />
            </clipPath>
          </defs>
          <g id="Flow" transform="translate(-426 -4494)">
            <g id="Line" transform="translate(0 -417)">
              <line id="Line_152" data-name="Line 152" y2={455} transform="translate(719.793 4998.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_172" data-name="Line 172" y1={30} transform="translate(719.793 5541.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_173" data-name="Line 173" x2="243.707" transform="translate(597.939 5571.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_174" data-name="Line 174" y2={54} transform="translate(597.939 5571.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_175" data-name="Line 175" y2={54} transform="translate(841.646 5571.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_176" data-name="Line 176" y2={650} transform="translate(532.646 5716.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_177" data-name="Line 177" x1="45.854" transform="translate(532.646 6366.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_178" data-name="Line 178" x1="45.854" transform="translate(532.646 6262.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_179" data-name="Line 179" x1="45.854" transform="translate(532.646 6161.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_180" data-name="Line 180" x1="45.854" transform="translate(532.646 6059.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_181" data-name="Line 181" x1="45.854" transform="translate(532.646 5955.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_182" data-name="Line 182" x1="45.854" transform="translate(532.646 5853.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_183" data-name="Line 183" y1={91} transform="translate(1194.646 5153.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_184" data-name="Line 184" y1={487} transform="translate(1276.646 5325.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_192" data-name="Line 192" x1="29.146" transform="translate(1247.5 5595.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_185" data-name="Line 185" x2={169} transform="translate(1432.5 5845.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_186" data-name="Line 186" y1={564} transform="translate(1601.5 5845.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_187" data-name="Line 187" x1={85} transform="translate(1516.5 6409.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_188" data-name="Line 188" x1={85} transform="translate(1516.5 6329.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_189" data-name="Line 189" x1={85} transform="translate(1516.5 6222.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_190" data-name="Line 190" x1={85} transform="translate(1516.5 6115.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_191" data-name="Line 191" x1={85} transform="translate(1516.5 6035.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5} />
              <line id="Line_193" data-name="Line 193" y1={68} transform="translate(851.5 5275.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_194" data-name="Line 194" x2={282} transform="translate(851.5 5343.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_195" data-name="Line 195" y1={66} transform="translate(1132.5 5344.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_196" data-name="Line 196" x2={144} transform="translate(1132.5 5410.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_197" data-name="Line 197" x2={42} transform="translate(1219.5 6040.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_198" data-name="Line 198" x2={42} transform="translate(1219.5 6120.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_199" data-name="Line 199" y1={80} transform="translate(1219.5 6040.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_200" data-name="Line 200" x1={30} transform="translate(1189.5 6080.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_201" data-name="Line 201" x2={76} transform="translate(964.5 6080.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_202" data-name="Line 202" y2={195} transform="translate(964.5 6080.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
              <line id="Line_203" data-name="Line 203" x2={71} transform="translate(875.5 6370.5)" fill="none" stroke="#5c5c5c" strokeWidth={2} opacity="0.2" />
            </g>
            <g id="Admin" transform="translate(-330.776 -7121.812)">
              <rect id="Rectangle_298" data-name="Rectangle 298" width={179} height={43} rx="21.5" transform="translate(1555.776 11830.812)" />
              <text id="Admin_Dashboard" data-name="Admin Dashboard" transform="translate(1645.776 11856.812)" fill="#fff" fontSize={14} fontFamily="Metropolis-Bold, Metropolis" fontWeight={700}><tspan x="-62.335" y={0}>Admin Dashboard</tspan></text>
              <g id="Admin-2" data-name="Admin" transform="translate(1486.777 11681.984)">
                <g id="Group_7747" data-name="Group 7747" transform="translate(147.628 6.104)">
                  <rect id="Rectangle_519" data-name="Rectangle 519" width="23.863" height="22.138" rx="11.069" transform="translate(0 0)" fill="#ffd6d6" />
                  <g id="Group_7746" data-name="Group 7746" transform="translate(6.411 6.264)">
                    <path id="Path_5814" data-name="Path 5814" d="M1352.189,753.447l-13.24-1.143,6.915,4.492Z" transform="translate(-1338.949 -752.305)" fill="#ff6464" />
                    <path id="Path_5815" data-name="Path 5815" d="M1384.616,763.917l-6.461-11.612,6.929,4.471Z" transform="translate(-1378.155 -752.305)" fill="#f14f4f" />
                  </g>
                </g>
                <g id="Group_7759" data-name="Group 7759" transform="translate(0 10.423)">
                  <g id="Group_7748" data-name="Group 7748" transform="translate(101.967 26.448)">
                    <path id="Path_5816" data-name="Path 5816" d="M1295.659,904.535h20.471s9.076.615,5.818,15.853-13.963,46.123-10.7,55.465h-22.806Z" transform="translate(-1249.211 -904.535)" fill="#fbb6b6" />
                    <path id="Path_5817" data-name="Path 5817" d="M1362.652,960.306c2.71-13.3,7.073-35.747,9.386-47.7a9.958,9.958,0,0,1,9.778-8.066h32.2a9.959,9.959,0,0,1,9.813,11.662l-8.918,51.4a9.959,9.959,0,0,1-10.9,8.2l-32.687-3.6A9.958,9.958,0,0,1,1362.652,960.306Z" transform="translate(-1362.449 -904.535)" fill="#ffd6d6" />
                  </g>
                  <g id="Group_7755" data-name="Group 7755" transform="translate(38.821 0)">
                    <path id="Path_5827" data-name="Path 5827" d="M1500.787,975.742l-5.343,56.169s6.069,6.48,30.1,8.7c.6.059,1.208.115,1.832.163,13.79,1.114,18.909-16.676,20.7-33.282a178.245,178.245,0,0,0,.635-27.461C1516.513,966.479,1500.787,975.742,1500.787,975.742Z" transform="translate(-1445.697 -935.24)" fill="#ff6464" />
                    <path id="Path_5828" data-name="Path 5828" d="M1725.809,1027.393c4.769,2.214,33.134,4.072,35.859,3.288,1.4-.4,7.245-5.554,13.128-11.891l.005,0c5.568-5.994,11.181-13.048,13.074-18.125a31.065,31.065,0,0,0,.547-19.7s-7.238-.03-10.188,1.751-19.821,32.854-22.076,33.674-28.979,2.352-32.038,3.8C1724.12,1020.194,1721.044,1025.179,1725.809,1027.393Z" transform="translate(-1723.144 -942.214)" fill="#ff6464" />
                    <path id="Path_5829" data-name="Path 5829" d="M1578.964,927.753l.007,1.757s-.487,5.492,6.276,6.3,11.892-2.124,11.715-6.363c-.6-3.283-1.27-5.538-1.311-8.254a19.156,19.156,0,0,1,.246-3.289l-13.107-.282-3.861.625Z" transform="translate(-1507.136 -889.071)" fill="#ebb67f" />
                    <path id="Path_5830" data-name="Path 5830" d="M1585.574,919.722l.035,9.5a11.083,11.083,0,0,0,4.723,1.05c6.024.009,12.02-9.685,12.21-10.894Z" transform="translate(-1513.781 -890.541)" fill="#de9b59" />
                    <path id="Path_5831" data-name="Path 5831" d="M1568.779,805.813s16.781,3.417,18.748-7.067,5.458-17.1-5.174-19.728-13.47.984-14.977,4.323S1564.465,804.654,1568.779,805.813Z" transform="translate(-1497.759 -772.017)" fill="#ebb67f" />
                    <path id="Path_5832" data-name="Path 5832" d="M1537.224,743.752a35.183,35.183,0,0,0,10.207-2.745c4.484-1.942,10.135.213,11.969,1.183a2.737,2.737,0,0,0-.446-1.1,2.464,2.464,0,0,1,.943,1.4c.085.063.123.106.1.12a3.156,3.156,0,0,1,1.534.1s-.42-.061-.57.209a.253.253,0,0,1-.067.074c6.985,3.388,7.614,14.437,5.36,16.99-2.711,3.071-5.967,9.993-5.967,9.993a5.938,5.938,0,0,1-.685-4.344c.5-2.706-1.841-2.709-4.792-3.944s-.485-4.675-3.065-6.771-12.055-.88-11.929-2.479C1536.3,751.152,1537.224,744.113,1537.224,743.752Z" transform="translate(-1470.858 -740.223)" fill="#2c3a64" />
                    <path id="Path_5833" data-name="Path 5833" d="M1501.1,1222.228c.6.059,1.208.115,1.832.163,13.79,1.114,18.909-16.676,20.7-33.281C1521.962,1189.929,1499.356,1201.435,1501.1,1222.228Z" transform="translate(-1421.255 -1116.854)" fill="#f14f4f" />
                    <path id="Path_5834" data-name="Path 5834" d="M1579.151,1283.546s-4.862-3.156-6.472-2.868-8.191,2.22-9.1,3.3c-1.41,1.678-3.9,4.345-2.482,4.628s13.938.911,15.381.788S1581.29,1287.859,1579.151,1283.546Z" transform="translate(-1487.076 -1193.668)" fill="#ea9e6a" />
                    <path id="Path_5835" data-name="Path 5835" d="M1311.6,1006.417s10.148,2.271,15.229,8.106c4.619,5.3,18,31.2,21.678,40.515.586,1.483-1.064,4.711-2.392,5.595-3.325,2.213-10.171,3.82-27.108,1.026l-15.141-2.208s-1.105-6.8,2.187-8.219l24.516-.34s-14.043-14.533-16.755-18.711S1311.6,1006.417,1311.6,1006.417Z" transform="translate(-1214.496 -963.569)" fill="#ff6464" />
                    <path id="Path_5836" data-name="Path 5836" d="M1816.109,1224.557c4.769,2.214,33.134,4.072,35.859,3.288,1.4-.4,7.245-5.554,13.129-11.891-.291.038-9.547,1.356-15.249,6.286,0,0-3.213,1.43-11.956.629S1817.919,1221.775,1816.109,1224.557Z" transform="translate(-1813.443 -1139.378)" fill="#f14f4f" />
                    <path id="Path_5837" data-name="Path 5837" d="M1441.081,1000.54s10.619,2.373,14.105,5.313,2.809,3.739,2.809,3.739-1.34,2.032-6.738-.2a105.712,105.712,0,0,1-12.467-6.643A2.43,2.43,0,0,1,1441.081,1000.54Z" transform="translate(-1345.661 -958.638)" fill="#f14f4f" />
                    <path id="Path_5838" data-name="Path 5838" d="M1716.973,971.6s-.64,1.543-1.608,1.753-10.593,2.8-11.857,2.063,2.267-3.75,7.341-3.966S1716.973,971.6,1716.973,971.6Z" transform="translate(-1647.155 -934.17)" fill="#f14f4f" />
                  </g>
                  <g id="Group_7756" data-name="Group 7756" transform="translate(18.573 57.575)">
                    <path id="Path_5839" data-name="Path 5839" d="M1745.876,1303.839H1778.3a2.585,2.585,0,0,0-2.585-2.585h-29.842Z" transform="translate(-1695.097 -1268.523)" fill="#ff4141" />
                    <path id="Path_5840" data-name="Path 5840" d="M1920.156,1097.914h-50.134l12.025,35.315h50.459Z" transform="translate(-1869.284 -1097.913)" fill="#ff4141" />
                    <path id="Path_5841" data-name="Path 5841" d="M1874.608,1097.909l12.025,35.316h50.459l-12.351-35.316Z" transform="translate(-1874.607 -1097.909)" fill="#263238" />
                    <path id="Path_5842" data-name="Path 5842" d="M2064.179,1192.848a3.26,3.26,0,0,0,3.008,2.443,1.881,1.881,0,0,0,1.879-2.443,3.26,3.26,0,0,0-3.007-2.443A1.882,1.882,0,0,0,2064.179,1192.848Z" transform="translate(-2037.151 -1175.516)" fill="#ff4141" />
                    <path id="Path_5843" data-name="Path 5843" d="M2042.258,1097.909l12.025,35.316h23.473l-28.115-35.316Z" transform="translate(-2042.258 -1097.909)" fill="#3c474c" />
                  </g>
                  <g id="Group_7758" data-name="Group 7758" transform="translate(0 92.891)">
                    <g id="Group_7757" data-name="Group 7757">
                      <path id="Path_5844" data-name="Path 5844" d="M1082.773,1345.655H1251.02l-4.344-14.392c-2.527-8.372-9.162-13.949-16.594-13.949H1098.8c-5.469,0-10.278,4.339-11.788,10.638Z" transform="translate(-1042.505 -1317.314)" fill="#263238" />
                      <path id="Path_5845" data-name="Path 5845" d="M1374.873,1345.655h161.161l.313-7.258c.495-11.462-7.076-21.083-16.59-21.083H1390.79c-5.431,0-10.207,4.339-11.706,10.638Z" transform="translate(-1374.873 -1317.314)" fill="#515b60" />
                      <path id="Path_5846" data-name="Path 5846" d="M1744.7,1345.655h118.728c6.6-11.446,12.605-21.765,16.569-28.341H1763.9a14.69,14.69,0,0,0-14.121,10.638Z" transform="translate(-1744.701 -1317.314)" fill="#3c474c" />
                    </g>
                  </g>
                </g>
                <g id="Group_7761" data-name="Group 7761" transform="translate(38.742)">
                  <path id="Path_5847" data-name="Path 5847" d="M1788.813,682.794h47.08a2.361,2.361,0,0,1,2.354,2.354V709.03a2.361,2.361,0,0,1-2.354,2.354h-3.981l1.922,8.117-12.065-8.117h-32.957a2.361,2.361,0,0,1-2.354-2.354V685.148A2.361,2.361,0,0,1,1788.813,682.794Z" transform="translate(-1786.459 -682.794)" fill="#ffd6d6" />
                  <g id="Group_7760" data-name="Group 7760" transform="translate(4.02 3.089)">
                    <path id="Path_5848" data-name="Path 5848" d="M1813.075,748.908h41.59a1.033,1.033,0,0,1,1.03,1.03h0a1.033,1.033,0,0,1-1.03,1.03h-41.59a1.033,1.033,0,0,1-1.03-1.03h0A1.033,1.033,0,0,1,1813.075,748.908Z" transform="translate(-1812.045 -741.355)" fill="#ff6464" />
                    <path id="Path_5849" data-name="Path 5849" d="M1813.075,777.549h41.59a1.03,1.03,0,0,1,0,2.06h-41.59a1.03,1.03,0,1,1,0-2.06Z" transform="translate(-1812.045 -765.386)" fill="#ff6464" />
                    <path id="Path_5850" data-name="Path 5850" d="M1813.075,806.19h41.59a1.03,1.03,0,0,1,0,2.06h-41.59a1.03,1.03,0,1,1,0-2.06Z" transform="translate(-1812.045 -789.417)" fill="#ff6464" />
                    <path id="Path_5851" data-name="Path 5851" d="M1837.63,704.438h-17.656a1.226,1.226,0,0,1,0-2.452h17.656a1.226,1.226,0,0,1,0,2.452Z" transform="translate(-1796.286 -701.986)" fill="#ff6464" />
                    <path id="Rectangle_520" data-name="Rectangle 520" d="M1.226,0h10.11a1.226,1.226,0,0,1,1.226,1.226v0a1.226,1.226,0,0,1-1.226,1.226H1.226A1.226,1.226,0,0,1,0,1.226v0A1.226,1.226,0,0,1,1.226,0Z" transform="translate(7.546 0)" fill="#ff6464" />
                  </g>
                </g>
              </g>
            </g>
            <g id="User_Balance" data-name="User Balance" transform="translate(572 4044.476)">
              <g id="Rectangle_45" data-name="Rectangle 45" transform="translate(571 784.524)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="User_Balance-2" data-name="User Balance" transform="translate(699 820.524)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-43.057" y={0}>User </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Balance</tspan></text>
            </g>
            <g id="User_Login" data-name="User Login" transform="translate(495.339 4479.015)">
              <g id="Ellipse_196" data-name="Ellipse 196" transform="translate(-69.339 53.985)" fill="none" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <circle cx={108} cy={108} r={108} stroke="none" />
                <circle cx={108} cy={108} r={107} fill="none" />
              </g>
            </g>
            <g id="User" transform="translate(-786.5 -7099.5)">
              <rect id="Rectangle_298-2" data-name="Rectangle 298" width={219} height={43} rx="21.5" transform="translate(1252 11719)" />
              <text id="User-2" data-name="User" transform="translate(1401 11745)" fill="#fff" fontSize={14} fontFamily="Metropolis-Bold, Metropolis" fontWeight={700}><tspan x="-15.799" y={0}>User</tspan></text>
              <g id="User_Login-2" data-name="User Login" transform="translate(1035.715 11514.74)">
                <g id="Group_27456" data-name="Group 27456" transform="translate(240.67 91.124)">
                  <path id="Path_19076" data-name="Path 19076" d="M303.645,206.489c-1.117-3.72,5.22-31.268,7.186-41.5,1.341-6.958-.549-12.352-5.043-17.094l-36.2-30.191-20.33-4.237c-4.269,9.482-3.586,18.685-.835,27.772,12.1,9.81,27.689,16.823,47.14,21.049-8.419,13.624-6.319,21.121-6.622,37.391-1.345,3.575,1.807,6.037,2.859,7.931C296.192,209.768,299.991,209.554,303.645,206.489Z" transform="translate(-226.191 -32.693)" fill="#352717" />
                  <path id="Path_19077" data-name="Path 19077" d="M274.895,144.224c-1.482-1.894-3.091-3.441-4.226-5.021-3.654,3.065-7.454,3.279-11.846,1.124-.018.849.018,1.706.08,2.646a20.369,20.369,0,0,0,6.575,9.85c4.128,3.007,8.173,5.408,11.726,4.038l7.511-4.269Z" transform="translate(-193.215 34.593)" fill="#ffb091" />
                  <path id="Path_19078" data-name="Path 19078" d="M261.146,137.985c-2.82,4.049-3,9.9-1.829,13.848,2.147,3.138,5.227,4.262,8.733,8.856l3.2,2.989c6.214,3.738,13.114,3.965,16.671,1.775,2.162-3.441.9-9.63-7.41-13.707,5.357,4.338.412,7.461-4.677,7.66-3.452-.408-6.022-3.965-10.118-8.773C261.908,145.572,260.018,142.034,261.146,137.985Z" transform="translate(-193.69 31.408)" fill="#cf3e2d" />
                  <path id="Path_19079" data-name="Path 19079" d="M273.205,149.723a25.48,25.48,0,0,1-4.92-8.025,12.466,12.466,0,0,1-10.291-1.811c-1,3.246-2.111,5.379-1.652,8.216a24.362,24.362,0,0,0,7.613,10.179c4.468,3.163,8.795,5.7,12.258,4.4l7.848-4.544Z" transform="translate(-199.97 36.382)" fill="#ffb294" />
                  <path id="Path_19080" data-name="Path 19080" d="M258.586,139.729c-2.82,4.049-3,9.9-1.829,13.848,2.147,3.138,5.227,4.262,8.733,8.853l3.2,2.993c6.214,3.738,16.266,2.053,19.823-.134,2.162-3.441-2.256-7.721-10.562-11.8,5.357,4.338.412,7.461-4.677,7.66-3.452-.408-6.022-3.965-10.118-8.773C259.348,147.316,257.458,143.778,258.586,139.729Z" transform="translate(-200.384 35.969)" fill="#e65645" />
                  <path id="Path_19081" data-name="Path 19081" d="M310.486,210.969c-2.122-6.29-.058-27.928,1.337-42.427.68-7.042-1.392-12.41-7.052-16.653l-42.68-32.851-17.582-4.4c-3.4,9.539-7.367,21.522,1.851,28.224,13.2,10.273,27.989,17.373,48.084,22.144-8.231,15.612.878,35.7-1.431,43.182C294.765,212.516,303.748,214.7,310.486,210.969Z" transform="translate(-240.67 -29.628)" fill="#5c4425" />
                  <path id="Path_19082" data-name="Path 19082" d="M270.69,139c-.712-8.213-1.746-16.881-4.117-32.735-1.735-11.336-16.194-13.881-14.575,1.272,2.516,16.042,3.824,24.819,5.961,34.095.506,2.194,1.225,4.67,2.914,6.156,4.753,4.182,8.177,7.125,12.706,11.083l5.357-8.01Z" transform="translate(-211.379 -75.849)" fill="#fff" />
                  <path id="Path_19083" data-name="Path 19083" d="M297.445,137.563c-1.146,6.322-6.3,8.589-8.994,9.355a12.281,12.281,0,0,1-1.681.361,19.691,19.691,0,0,0-.777,14.239c-6.073,11.611-20.839,23-44.91,6.351,0,0,3.008-10.877,5.874-18.6,1.254-3.391.408-7.291-.488-11.7-.976-4.815-2.017-10.233-.43-16.259,3.976-15.113,37.872-24.262,37.872-24.262,8.961,2.852,9.912,10.2,8.683,18.28a20.1,20.1,0,0,0,2.1,12.446C296.382,130.948,297.943,134.8,297.445,137.563Z" transform="translate(-239.59 -75.622)" fill="#fff" />
                  <g id="Group_27453" data-name="Group 27453" transform="translate(38.212 84.672)">
                    <path id="Path_19084" data-name="Path 19084" d="M311.622,130.971l-37.128,21.436-23.253-13.425V137.84l37.128-20.294Z" transform="translate(-251.241 -106.707)" fill="#1a1a1a" />
                    <path id="Path_19085" data-name="Path 19085" d="M311.622,130.655l-37.128,21.436-23.253-13.425,37.128-21.436Z" transform="translate(-251.241 -107.533)" fill="#4d4d4d" />
                    <g id="Group_27452" data-name="Group 27452" transform="translate(11.358 16.255)">
                      <path id="Path_19086" data-name="Path 19086" d="M269.467,121.948l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-225.931 -114.2)" fill="#ccc" />
                      <path id="Path_19087" data-name="Path 19087" d="M268.7,122.39l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-227.937 -113.041)" fill="#ccc" />
                      <path id="Path_19088" data-name="Path 19088" d="M267.933,122.833l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-229.943 -111.883)" fill="#ccc" />
                      <path id="Path_19089" data-name="Path 19089" d="M267.165,123.276l-2.383,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-231.949 -110.725)" fill="#ccc" />
                      <path id="Path_19090" data-name="Path 19090" d="M266.4,123.719l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-233.955 -109.567)" fill="#ccc" />
                      <path id="Path_19091" data-name="Path 19091" d="M265.631,124.162l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-235.961 -108.409)" fill="#ccc" />
                      <path id="Path_19092" data-name="Path 19092" d="M264.864,124.605l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-237.967 -107.25)" fill="#ccc" />
                      <path id="Path_19093" data-name="Path 19093" d="M264.1,125.048l-2.384,1.376-1.819-1.05L262.278,124Z" transform="translate(-239.973 -106.092)" fill="#ccc" />
                      <path id="Path_19094" data-name="Path 19094" d="M263.33,125.491l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-241.98 -104.934)" fill="#ccc" />
                      <path id="Path_19095" data-name="Path 19095" d="M262.562,125.934l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-243.985 -103.776)" fill="#ccc" />
                      <path id="Path_19096" data-name="Path 19096" d="M261.8,126.377l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-245.992 -102.617)" fill="#ccc" />
                      <path id="Path_19097" data-name="Path 19097" d="M268.565,121.427l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-228.289 -115.561)" fill="#ccc" />
                      <path id="Path_19098" data-name="Path 19098" d="M267.8,121.87l-2.384,1.376L263.6,122.2l2.384-1.377Z" transform="translate(-230.296 -114.403)" fill="#ccc" />
                      <path id="Path_19099" data-name="Path 19099" d="M267.031,122.313l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-232.302 -113.245)" fill="#ccc" />
                      <path id="Path_19100" data-name="Path 19100" d="M266.264,122.756l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-234.308 -112.087)" fill="#ccc" />
                      <path id="Path_19101" data-name="Path 19101" d="M265.5,123.2l-2.384,1.377-1.819-1.05,2.384-1.376Z" transform="translate(-236.314 -110.928)" fill="#ccc" />
                      <path id="Path_19102" data-name="Path 19102" d="M264.729,123.641l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-238.32 -109.77)" fill="#ccc" />
                      <path id="Path_19103" data-name="Path 19103" d="M263.962,124.084l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-240.326 -108.612)" fill="#ccc" />
                      <path id="Path_19104" data-name="Path 19104" d="M263.195,124.527l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-242.332 -107.454)" fill="#ccc" />
                      <path id="Path_19105" data-name="Path 19105" d="M262.428,124.97l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-244.338 -106.296)" fill="#ccc" />
                      <path id="Path_19106" data-name="Path 19106" d="M261.66,125.413l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-246.344 -105.137)" fill="#ccc" />
                      <path id="Path_19107" data-name="Path 19107" d="M260.893,125.856l-2.384,1.377-1.819-1.05,2.384-1.376Z" transform="translate(-248.351 -103.979)" fill="#ccc" />
                      <path id="Path_19108" data-name="Path 19108" d="M267.988,121.093,265.6,122.47l-1.819-1.05,2.384-1.376Z" transform="translate(-229.798 -116.432)" fill="#ccc" />
                      <path id="Path_19109" data-name="Path 19109" d="M267.221,121.536l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-231.804 -115.274)" fill="#ccc" />
                      <path id="Path_19110" data-name="Path 19110" d="M266.454,121.979l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-233.81 -114.116)" fill="#ccc" />
                      <path id="Path_19111" data-name="Path 19111" d="M265.686,122.422,263.3,123.8l-1.819-1.05,2.383-1.376Z" transform="translate(-235.816 -112.957)" fill="#ccc" />
                      <path id="Path_19112" data-name="Path 19112" d="M264.92,122.865l-2.384,1.376-1.818-1.05,2.384-1.376Z" transform="translate(-237.822 -111.799)" fill="#ccc" />
                      <path id="Path_19113" data-name="Path 19113" d="M264.153,123.308l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-239.828 -110.641)" fill="#ccc" />
                      <path id="Path_19114" data-name="Path 19114" d="M263.385,123.751,261,125.127l-1.819-1.05,2.384-1.376Z" transform="translate(-241.834 -109.483)" fill="#ccc" />
                      <path id="Path_19115" data-name="Path 19115" d="M262.618,124.194l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-243.841 -108.325)" fill="#ccc" />
                      <path id="Path_19116" data-name="Path 19116" d="M261.851,124.637l-2.384,1.376-1.819-1.05,2.383-1.376Z" transform="translate(-245.846 -107.166)" fill="#ccc" />
                      <path id="Path_19117" data-name="Path 19117" d="M261.083,125.08l-2.384,1.376-1.818-1.05,2.384-1.376Z" transform="translate(-247.852 -106.008)" fill="#ccc" />
                      <path id="Path_19118" data-name="Path 19118" d="M267.412,120.76l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-231.306 -117.303)" fill="#ccc" />
                      <path id="Path_19119" data-name="Path 19119" d="M266.644,121.2l-2.384,1.376-1.818-1.05,2.384-1.376Z" transform="translate(-233.312 -116.145)" fill="#ccc" />
                      <path id="Path_19120" data-name="Path 19120" d="M265.877,121.646l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-235.318 -114.986)" fill="#ccc" />
                      <path id="Path_19121" data-name="Path 19121" d="M265.11,122.089l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-237.325 -113.828)" fill="#ccc" />
                      <path id="Path_19122" data-name="Path 19122" d="M264.342,122.532l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-239.33 -112.67)" fill="#ccc" />
                      <path id="Path_19123" data-name="Path 19123" d="M263.575,122.975l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-241.336 -111.512)" fill="#ccc" />
                      <path id="Path_19124" data-name="Path 19124" d="M262.808,123.418l-2.384,1.377-1.819-1.05,2.384-1.376Z" transform="translate(-243.342 -110.353)" fill="#ccc" />
                      <path id="Path_19125" data-name="Path 19125" d="M262.041,123.861l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-245.349 -109.195)" fill="#ccc" />
                      <path id="Path_19126" data-name="Path 19126" d="M261.274,124.3l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-247.355 -108.037)" fill="#ccc" />
                      <path id="Path_19127" data-name="Path 19127" d="M261.825,127.552l-2.085-1.2,2.773-1.6-1.818-1.05-2.774,1.6-.7.4-1.688.975,1.548.894.271.157,2.085,1.2Z" transform="translate(-251.367 -106.879)" fill="#ccc" />
                      <path id="Path_19128" data-name="Path 19128" d="M265.341,122.448l.726-.419,2.773-1.6-1.818-1.05-2.774,1.6-.726.42-1.657.957,1.819,1.05Z" transform="translate(-234.82 -118.173)" fill="#ccc" />
                      <path id="Path_19129" data-name="Path 19129" d="M265.3,121.313l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-236.826 -115.857)" fill="#ccc" />
                      <path id="Path_19130" data-name="Path 19130" d="M264.533,121.756l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-238.833 -114.699)" fill="#ccc" />
                      <path id="Path_19131" data-name="Path 19131" d="M263.766,122.2l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-240.839 -113.541)" fill="#ccc" />
                      <path id="Path_19132" data-name="Path 19132" d="M263,122.642l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-242.845 -112.382)" fill="#ccc" />
                      <path id="Path_19133" data-name="Path 19133" d="M262.231,123.085l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-244.851 -111.224)" fill="#ccc" />
                      <path id="Path_19134" data-name="Path 19134" d="M261.464,123.528,259.08,124.9l-1.818-1.05,2.384-1.376Z" transform="translate(-246.857 -110.066)" fill="#ccc" />
                      <path id="Path_19135" data-name="Path 19135" d="M260.7,123.971l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-248.863 -108.908)" fill="#ccc" />
                      <path id="Path_19136" data-name="Path 19136" d="M259.552,125.79l.408-.235,1.976-1.141-1.818-1.05-1.976,1.141-.408.235-2.773,1.6,1.819,1.05Z" transform="translate(-252.875 -107.75)" fill="#ccc" />
                      <path id="Path_19137" data-name="Path 19137" d="M266.258,120.095l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-234.322 -119.044)" fill="#ccc" />
                      <path id="Path_19138" data-name="Path 19138" d="M265.491,120.538l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-236.329 -117.886)" fill="#ccc" />
                      <path id="Path_19139" data-name="Path 19139" d="M264.723,120.98l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-238.335 -116.728)" fill="#ccc" />
                      <path id="Path_19140" data-name="Path 19140" d="M259.7,128.516l1.192-.688.39-.225,2.384-1.377.389-.225,2.384-1.377.39-.224,2.384-1.377,2.773-1.6-1.819-1.05-2.774,1.6-2.383,1.376-.39.225-2.384,1.376-.389.225-2.384,1.377-.389.225-1.192.688-1.192.688,1.819,1.05Z" transform="translate(-248.365 -115.57)" fill="#ccc" />
                      <path id="Path_19141" data-name="Path 19141" d="M260.12,123.638l-2.384,1.376-1.819-1.05,2.384-1.377Z" transform="translate(-250.371 -109.779)" fill="#ccc" />
                      <path id="Path_19142" data-name="Path 19142" d="M259.353,124.081l-2.384,1.376-1.819-1.05,2.384-1.376Z" transform="translate(-252.377 -108.62)" fill="#ccc" />
                      <path id="Path_19143" data-name="Path 19143" d="M258.586,124.524,256.2,125.9l-1.819-1.05,2.384-1.376Z" transform="translate(-254.383 -107.462)" fill="#ccc" />
                    </g>
                    <path id="Path_19144" data-name="Path 19144" d="M270.605,122.563l-10.128,5.847-5.161-2.98,10.128-5.847Z" transform="translate(-240.583 -101.379)" fill="#333" />
                    <path id="Path_19145" data-name="Path 19145" d="M306.366,114.548l-37.128,21.436-11.581,23.15L294.785,137.7l12.289-22.323Z" transform="translate(-234.465 -114.548)" fill="#1a1a1a" />
                    <path id="Path_19146" data-name="Path 19146" d="M306.562,114.776l-37.128,21.436-11.58,23.15,37.128-21.436Z" transform="translate(-233.952 -113.95)" fill="#4d4d4d" />
                  </g>
                  <path id="Path_19147" data-name="Path 19147" d="M254,126.155l2.827,1.442a13.264,13.264,0,0,0,8.625,2.823l2.885,1.923,2.173,3.065c1.562,1.9,2.245.683,1.5-.878l-1.175-1.984-2.458-2.935.925-.578,2.082.6,2.99,1.641.781.86a1.447,1.447,0,0,0,1.7.333c.141-.065.437.083.437.083a.964.964,0,0,0,1.269-.383,1.519,1.519,0,0,0-.018-1.551l-1.334-2.176-2.407-1.992,1.948.311,1.572,1.121,1.03,1.254c1.121,1.215,1.818.119,1.345-1.1l-1.222-1.847-3.25-2.422-5.115-1.54-9.825-.1-4.063-1.6-3.882-.629C250.375,118,251.748,124.405,254,126.155Z" transform="translate(-211.978 -16.789)" fill="#ffb294" />
                  <g id="Group_27455" data-name="Group 27455" transform="translate(55.758 76.19)">
                    <g id="Group_27454" data-name="Group 27454" clipPath="url(#clip-path)">
                      <path id="Path_19148" data-name="Path 19148" d="M260.436,119.589l.683.915.459,4.786,1.916,3.163,1.03,1.977,2.115,1.8c1.059.716,1.8-.539,1.041-1.844l-1.486-1.988-.235-2.248a3.709,3.709,0,0,1,3.864-.152s1.222,2.646,1.258,2.773.933,2.588.933,2.588c.611,2.14,2.281,1.75,1.992-.025l-.875-3.177-.6-2.465,2.375,2.841,1.352,2.527c.835,1.431,2.256,1.092,1.833-.289l-1.023-2.35-1.175-2.27,2.263,2.006.871,1.974a1.587,1.587,0,0,0,1.4,1.084.751.751,0,0,0,.434-1.225c.34-.275.166-1-.058-1.381l-2.046-2.928-5.979-4.88-5.6-3.409-4.663-2.418C260.27,113.169,259.2,117.593,260.436,119.589Z" transform="translate(-246.111 -106.066)" fill="#ffb294" />
                    </g>
                  </g>
                  <path id="Path_19150" data-name="Path 19150" d="M286.77,127.556a19.691,19.691,0,0,0-.777,14.239c-6.073,11.611-20.839,23-44.91,6.351,0,0,3.008-10.877,5.874-18.6,1.254-3.391.408-7.291-.488-11.7L255.69,104.6c9.944,2.429,7.309,27.921,18.851,29.956,10.758,2.024,8.072-10.555,13.91-7.356A12.281,12.281,0,0,1,286.77,127.556Z" transform="translate(-239.59 -55.899)" fill="gray" opacity="0.3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
                  <path id="Path_19151" data-name="Path 19151" d="M242.443,115.778c2.516,16.042,2.935,21.157,5.072,30.436a10.129,10.129,0,0,0,3.47,5.578c7.631,6.207,16.266,13.541,30,19.032.546-2.848,2-4.717,4.728-5.223l-25.81-23.221c0-8.213-.517-16.3-2.888-32.153C253.547,99.005,240.823,100.624,242.443,115.778Z" transform="translate(-236.406 -60.257)" fill="#fff" />
                  <path id="Path_19152" data-name="Path 19152" d="M253.814,95.731,248.789,112.1s12.178,23.6,16.552,30.328a61.6,61.6,0,0,0,.5-27.722V97.64Z" transform="translate(-219.44 -79.076)" fill="#ffb294" />
                  <path id="Path_19153" data-name="Path 19153" d="M254.42,98.042l-3.159,3.253c-1.905,2.234-4.508,5.6-4.341,10.443l5.3-.535-4.956,6.789c9.167,2.415,17.864,9.951,23.341,19.95C266.71,119.46,255.118,115.5,254.42,98.042Z" transform="translate(-224.349 -73.034)" fill="#e6e6e6" />
                  <path id="Path_19154" data-name="Path 19154" d="M252.506,99.179c3.871,5.979,5.3,23.518,3.116,38.946,3.918-11.65,7.5-23.214,7.963-30.3l-4.4-1.3L262.938,105c-1.352-3.188-3.17-5.917-6.04-7.624Z" transform="translate(-209.721 -74.778)" fill="#e6e6e6" />
                  <path id="Path_19155" data-name="Path 19155" d="M250.243,98.587s3.156,15.927,9,14.835c4.186-.781,4.446-13.62,4.446-13.62Z" transform="translate(-215.639 -71.609)" fill="#ffc8b3" style={{ mixBlendMode: 'multiply', isolation: 'isolate' }} />
                  <path id="Path_19156" data-name="Path 19156" d="M275.178,97.4s.427,17.235-9.753,22.9c-4.518,2.516-8.643-2.082-8.643-2.082-4.446-4.7-7.472-9.312-7.146-13.707L255.5,93.764Z" transform="translate(-217.288 -84.22)" fill="#ffb294" />
                  <path id="Path_19157" data-name="Path 19157" d="M249.538,125.545c4.884,1.721,13.743-4.352,13.942-3.25.174.954-1.33,3.268-2.393,5.205,5.314-.694,15.945-10.056,16.606-19.722,1.464,2.068,1.247,5.672.43,8.238,5.05-1.7,12.626-25.1-15.7-24.891C240.927,91.28,249.538,125.545,249.538,125.545Z" transform="translate(-221.935 -91.124)" fill="#722811" />
                  <path id="Path_19158" data-name="Path 19158" d="M271.606,123.91l-8.372-12.034q-4.094,2.332-5.357,8.01c2.812,2.458,4.312,3.814,8.567,7.6C266.957,125.269,269.571,122.854,271.606,123.91Z" transform="translate(-195.677 -36.861)" fill="#fff" />
                </g>
              </g>
            </g>
            <text id="User_Can_be_Login_or_Register" data-name="User Can Login 
or Register" transform="translate(707 4510)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>User Can </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Login </tspan><tspan x={0} y={20}>or</tspan><tspan y={20} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Register</tspan></text>
            <g id="KYC" transform="translate(104.611 4253.075)">
              <g id="Rectangle_45-2" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Completes_KYC_Security" data-name="Completes KYC/Security" transform="translate(698 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-81.508" y={0}>Completes </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>KYC/Security</tspan></text>
            </g>
            <g id="KYC-2" data-name="KYC" transform="translate(-677.481 4090.151)">
              <g id="Group_26387" data-name="Group 26387" transform="translate(1196.48 947.924)">
                <rect id="Rectangle_8822" data-name="Rectangle 8822" width={128} height="59.565" rx={10} fill="#c2c8e3" />
              </g>
              <g id="Group_26397" data-name="Group 26397" transform="translate(1205.358 956.605)">
                <g id="Group_26388" data-name="Group 26388">
                  <path id="Path_19061" data-name="Path 19061" d="M1253.336,984.269a20.2,20.2,0,0,1-6.809,15.1c-.12.1-.24.208-.364.307a19.809,19.809,0,0,1-1.949,1.454,21.014,21.014,0,0,1-2.219,1.265,20.109,20.109,0,0,1-8.8,2.022c-.162,0-.318,0-.474-.011a20,20,0,0,1-5-.744c-.292-.084-.578-.167-.859-.261v-.005a20.233,20.233,0,0,1-4.944-2.422v-.005a20.463,20.463,0,0,1-3.137-2.625,20.088,20.088,0,0,1-5.736-14.071,20.145,20.145,0,0,1,40.29,0Z" transform="translate(-1213.046 -964.124)" fill="#e1efff" />
                </g>
                <g id="Group_26396" data-name="Group 26396" transform="translate(3.678 3.438)">
                  <g id="Group_26389" data-name="Group 26389" transform="translate(4.147)">
                    <path id="Path_19062" data-name="Path 19062" d="M1241.54,970.569a12.507,12.507,0,0,0-7.388,1.851c-3.472,2.264-3.438,12.832-5.653,15.3-3.522,3.917,4.7,6.767,9.846,7.025,4.179.211,15.1-.764,13.278-3.853C1247.732,984.3,1253.4,971.24,1241.54,970.569Z" transform="translate(-1227.648 -970.54)" fill="#3f210a" />
                  </g>
                  <g id="Group_26390" data-name="Group 26390" transform="translate(0 21.622)">
                    <path id="Path_19063" data-name="Path 19063" d="M1248.525,1015.74a6.29,6.29,0,0,0-3.136-3.386,3.67,3.67,0,0,0-.646-.25,43.1,43.1,0,0,0-4.865-.86,40.743,40.743,0,0,0-7.684-.282,24.843,24.843,0,0,0-4.8.766l-.438.125a11.481,11.481,0,0,0-3.714,1.677,9.6,9.6,0,0,0-3.334,4.042,19.9,19.9,0,0,0,2.058,2.47,20.448,20.448,0,0,0,3.137,2.625v.005a20.234,20.234,0,0,0,4.944,2.422v.005c.281.094.567.177.86.261a19.992,19.992,0,0,0,5,.744c.156.011.312.011.474.011a20.109,20.109,0,0,0,8.8-2.022,21.014,21.014,0,0,0,2.219-1.265,19.843,19.843,0,0,0,1.949-1.454A27.682,27.682,0,0,0,1248.525,1015.74Z" transform="translate(-1219.909 -1010.888)" fill="#d6b0a3" />
                  </g>
                  <g id="Group_26391" data-name="Group 26391" transform="translate(11.977 15.682)">
                    <path id="Path_19064" data-name="Path 19064" d="M1244.48,1009.663c-2.326-.75-2.458-2.279-2.029-3.5a19.144,19.144,0,0,0,.9-6.359l.395.076,6.643,1.28s-.14,2.089-.135,4.057v.049a6.882,6.882,0,0,1-.792,4.112C1248.94,1010.3,1247.208,1010.543,1244.48,1009.663Z" transform="translate(-1242.258 -999.804)" fill="#ffb27d" />
                  </g>
                  <g id="Group_26392" data-name="Group 26392" transform="translate(13.463 15.758)">
                    <path id="Path_19065" data-name="Path 19065" d="M1251.676,1001.225s-.14,2.089-.135,4.057c-3.714-.086-5.694-3.463-6.509-5.336Z" transform="translate(-1245.032 -999.945)" fill="#e8945b" />
                  </g>
                  <g id="Group_26393" data-name="Group 26393" transform="translate(12.415 4.085)">
                    <path id="Path_19066" data-name="Path 19066" d="M1252.018,992.971s-8.786.191-8.82-5.282-1.192-9.134,4.416-9.487,6.71,1.728,7.167,3.552S1254.3,992.781,1252.018,992.971Z" transform="translate(-1243.076 -978.162)" fill="#ffb27d" />
                  </g>
                  <g id="Group_26394" data-name="Group 26394" transform="translate(9.6 2.868)">
                    <path id="Path_19067" data-name="Path 19067" d="M1249.177,976.993s-2.372,6.433-6.06,7.4-5.294-.012-5.294-.012a10.451,10.451,0,0,0,3.388-6.194S1247.347,973.947,1249.177,976.993Z" transform="translate(-1237.824 -975.892)" fill="#3f210a" />
                  </g>
                  <g id="Group_26395" data-name="Group 26395" transform="translate(20.36 3.438)">
                    <path id="Path_19068" data-name="Path 19068" d="M1257.9,978.011a5.45,5.45,0,0,1,2.528,1.954,8.846,8.846,0,0,1,1.214,5.019s1.79-4.374-.26-6.832C1259.211,975.546,1257.9,978.011,1257.9,978.011Z" transform="translate(-1257.901 -976.956)" fill="#3f210a" />
                  </g>
                </g>
              </g>
              <g id="Group_26398" data-name="Group 26398" transform="translate(1254.749 958.515)">
                <rect id="Rectangle_8823" data-name="Rectangle 8823" width="25.329" height="3.057" transform="translate(0 0)" fill="#e1efff" />
              </g>
              <g id="Group_26399" data-name="Group 26399" transform="translate(1254.749 984.404)">
                <rect id="Rectangle_8824" data-name="Rectangle 8824" width="44.29" height="1.838" transform="translate(0 0)" fill="#e1efff" />
              </g>
              <g id="Group_26402" data-name="Group 26402" transform="translate(1254.749 977.707)">
                <g id="Group_26400" data-name="Group 26400" transform="translate(0)">
                  <rect id="Rectangle_8825" data-name="Rectangle 8825" width="27.037" height="1.838" fill="#e1efff" />
                </g>
                <g id="Group_26401" data-name="Group 26401" transform="translate(32.698)">
                  <rect id="Rectangle_8826" data-name="Rectangle 8826" width="27.037" height="1.838" fill="#e1efff" />
                </g>
              </g>
              <g id="Group_26405" data-name="Group 26405" transform="translate(1254.749 991.102)">
                <g id="Group_26403" data-name="Group 26403" transform="translate(0 0)">
                  <rect id="Rectangle_8827" data-name="Rectangle 8827" width="27.037" height="1.838" fill="#e1efff" />
                </g>
                <g id="Group_26404" data-name="Group 26404" transform="translate(32.698 0)">
                  <rect id="Rectangle_8828" data-name="Rectangle 8828" width="27.037" height="1.838" fill="#e1efff" />
                </g>
              </g>
              <g id="Group_26406" data-name="Group 26406" transform="translate(1285.014 958.515)">
                <rect id="Rectangle_8829" data-name="Rectangle 8829" width="17.884" height="3.057" transform="translate(0 0)" fill="#e1efff" />
              </g>
              <g id="Group_26416" data-name="Group 26416" transform="translate(191.998 -34.113)">
                <g id="Group_26409" data-name="Group 26409" transform="translate(1010.8 990.455)">
                  <g id="Group_26408" data-name="Group 26408" transform="translate(0 0)">
                    <path id="Path_19069" data-name="Path 19069" d="M1012.5,1000.168h-1.7v-9.713h9.389v1.7H1012.5Z" transform="translate(-1010.8 -990.455)" fill="#fff" />
                  </g>
                </g>
                <g id="Group_26411" data-name="Group 26411" transform="translate(1047.038 990.617)">
                  <g id="Group_26410" data-name="Group 26410" transform="translate(0 0)">
                    <path id="Path_19070" data-name="Path 19070" d="M1142.423,1000.389h-1.7V992.7h-8.015V991h9.712Z" transform="translate(-1132.711 -991.001)" fill="#fff" />
                  </g>
                </g>
                <g id="Group_26413" data-name="Group 26413" transform="translate(1047.362 1021.471)">
                  <g id="Group_26412" data-name="Group 26412" transform="translate(0 0)">
                    <path id="Path_19071" data-name="Path 19071" d="M1143.189,1104.511H1133.8v-1.7h7.691V1094.8h1.7Z" transform="translate(-1133.801 -1094.799)" fill="#fff" />
                  </g>
                </g>
                <g id="Group_26415" data-name="Group 26415" transform="translate(1010.8 1021.633)">
                  <g id="Group_26414" data-name="Group 26414">
                    <path id="Path_19072" data-name="Path 19072" d="M1020.512,1104.731H1010.8v-9.389h1.7v7.691h8.015Z" transform="translate(-1010.8 -1095.343)" fill="#fff" />
                  </g>
                </g>
              </g>
            </g>
            <text id="Two-Factor_Authentication_2FA_Mail_SMS" data-name="Two-Factor Authentication 
2FA Mail/SMS" transform="translate(769 4812.122)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Two-Factor Authentication </tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={20}>2FA Mail/SMS</tspan></tspan></text>
            <text id="Two-Factor_Authentication_2FA_Reset" data-name="Two-Factor Authentication 
2FA Reset" transform="translate(1311 4987.5)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x={0} y={0}>Two-Factor Authentication </tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={20}>2FA Reset</tspan></tspan></text>
            <g id="Admin_Disputes" data-name="Admin Disputes" transform="translate(543 4460)">
              <circle id="Ellipse_134" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(689 711)" fill="#ff6464" />
              <text id="Admin_Disputes-2" data-name="Admin
Disputes" transform="translate(669 714)" fill="#383838" fontSize={16} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500} letterSpacing="0.03em"><tspan x="-51.104" y={0}>Admin</tspan><tspan fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x="-71.408" y={20}>Disputes</tspan></tspan></text>
            </g>
            <g id="Website_Content" data-name="Website Content" transform="translate(707 4350)">
              <g id="Rectangle_45-3" data-name="Rectangle 45" transform="translate(604 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={200} height={105} rx={20} stroke="none" />
                <rect x={1} y={1} width={198} height={103} rx={19} fill="none" />
              </g>
              <g id="Group_27450" data-name="Group 27450" transform="translate(5 2)">
                <text id="Buy_Trade" data-name="Buy Trade" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-32.683" y={0}>Buy </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Trade</tspan></text>
                <text id="Sell_Trade" data-name="Sell Trade" transform="translate(699 861)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-32.221" y={0}>Sell </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Trade</tspan></text>
              </g>
            </g>
            <g id="Complete_Trade" data-name="Complete Trade" transform="translate(572 4613.429)">
              <g id="Rectangle_45-4" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Complete_Trade-2" data-name="Complete Trade" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-52.521" y={0}>Complete </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Trade</tspan></text>
            </g>
            <g id="Withdraw_Request" data-name="Withdraw Request" transform="translate(688 4803)">
              <g id="Rectangle_45-5" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Withdraw_Request-2" data-name="Withdraw Request" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-60.669" y={0}>Withdraw </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Request</tspan></text>
            </g>
            <g id="Withdraw_History" data-name="Withdraw History" transform="translate(688 4885)">
              <g id="Rectangle_45-6" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Withdraw_History-2" data-name="Withdraw History" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-57.456" y={0}>Withdraw </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>History</tspan></text>
            </g>
            <g id="Commission_History" data-name="Commission History" transform="translate(688 4992)">
              <g id="Rectangle_45-7" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Commission_History-2" data-name="Commission History" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-65.989" y={0}>Commission </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>History</tspan></text>
            </g>
            <g id="Wallet_Transaction" data-name="Wallet Transaction" transform="translate(688 5099)">
              <g id="Rectangle_45-8" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Wallet_Transaction-2" data-name="Wallet Transaction" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-60.732" y={0}>Wallet </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Transaction</tspan></text>
            </g>
            <g id="User_Message" data-name="User Message" transform="translate(688 5181)">
              <g id="Rectangle_45-9" data-name="Rectangle 45" transform="translate(571 785)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="User_Message-2" data-name="User Message" transform="translate(699 821)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-46.592" y={0}>User </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Message</tspan></text>
            </g>
            <g id="User_Dashboard" data-name="User Dashboard" transform="translate(-133 4424.429)">
              <g id="Rectangle_45-10" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="User_Dashboard-2" data-name="User Dashboard" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-53.641" y={0}>User </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Dashboard</tspan></text>
            </g>
            <g id="My_Profile" data-name="My Profile" transform="translate(152 4424.429)">
              <g id="Rectangle_45-11" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="My_Profile-2" data-name="My Profile" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-33.761" y={0}>My </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Profile</tspan></text>
            </g>
            <g id="Price_Chart" data-name="Price Chart" transform="translate(12 4622.429)">
              <g id="Rectangle_45-12" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Price_Chart-2" data-name="Price Chart" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-35.854" y={0}>Price </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Chart</tspan></text>
            </g>
            <g id="Wallet_Balance" data-name="Wallet Balance" transform="translate(12 4724.429)">
              <g id="Rectangle_45-13" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={311} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={309} height={60} rx={24} fill="none" />
              </g>
              <text id="Wallet_Balance_Deposit_Withdraw" data-name="Wallet Balance Deposit/Withdraw" transform="translate(727 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-113.575" y={0}>Wallet </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Balance Deposit/Withdraw</tspan></text>
            </g>
            <g id="View_Ads_Buy_Sell" data-name="View Ads Buy/Sell" transform="translate(12 4826.429)">
              <g id="Rectangle_45-14" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="View_Ads_Buy_Sell-2" data-name="View Ads Buy/Sell" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-59.815" y={0}>View Ads </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Buy/Sell</tspan></text>
            </g>
            <g id="Post_Ads_Buy_Sell" data-name="Post Ads Buy/Sell" transform="translate(12 4928.429)">
              <g id="Rectangle_45-15" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Post_Ads_Buy_Sell-2" data-name="Post Ads Buy/Sell" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-57.603" y={0}>Post Ads </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>Buy/Sell</tspan></text>
            </g>
            <g id="User_To_User_Message" data-name="User To User  Message" transform="translate(12 5030.429)">
              <g id="Rectangle_45-16" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="User_To_User_Message-2" data-name="User To User  Message" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-73.892" y={0}>User To User </tspan><tspan y={0} xmlSpace="preserve" fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}> Message</tspan></text>
            </g>
            <g id="Escrow_System" data-name="Escrow System" transform="translate(12 5132.429)">
              <g id="Rectangle_45-17" data-name="Rectangle 45" transform="translate(571 784.571)" fill="#fff" stroke="#5c5c5c" strokeWidth={2} strokeDasharray={5}>
                <rect width={255} height={62} rx={25} stroke="none" />
                <rect x={1} y={1} width={253} height={60} rx={24} fill="none" />
              </g>
              <text id="Escrow_System-2" data-name="Escrow System" transform="translate(699 820.571)" fill="#383838" fontSize={14} fontFamily="AvenirNext-Medium, Avenir Next" fontWeight={500}><tspan x="-49.182" y={0}>Escrow </tspan><tspan y={0} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}>System</tspan></text>
              <g id="Deposit" transform="translate(582.117 579.685)">
                <path id="Path_19159" data-name="Path 19159" d="M443.774,24.937c-5.263,1.581-8.669,3.68-9.5,6.657-3.665,13.116,9.1,22.553,6.136,33.181L451.216,62l4.917-24Z" transform="translate(55.307 1.698)" fill="#632404" fillRule="evenodd" />
                <g id="Group_27457" data-name="Group 27457" transform="translate(469.112 61.937)">
                  <path id="Path_19160" data-name="Path 19160" d="M440.912,41.1c-6.068,7.13-6.46,27.06-8.12,36.689l8.534,5.066c3.164-9.94,9.035-25.5,9.915-35.407.342-3.907-1.269-7.524-4.049-8.2A6.982,6.982,0,0,0,440.912,41.1Z" transform="translate(-415.023 -39.097)" fill="#cbbecc" fillRule="evenodd" />
                  <path id="Path_19161" data-name="Path 19161" d="M425.665,83.986c4.6-7.534,7.337-14.924,12.014-21.753,2.047-2.982,4.488-5.724,6.856-8.771,7.335,2.511,9.224,5.819,6.726,10.536-3.52,6.649-15.422,19.092-20.414,24.5Z" transform="translate(-425.665 -17.646)" fill="#cbbecc" fillRule="evenodd" />
                </g>
                <path id="Path_19162" data-name="Path 19162" d="M440.279,60.8H438.3l.332,18.14c-.536,10.506-2.446,31.162-2.765,41.583-.2,6.7,1.493,10.025,1.281,15.014-.6,14.183,1.249,28.08,1.483,42.712l6.432,6.183c3.014-22.059,7.774-34.1,7.255-46.751-.865-21.077.01-20.758,2.274-33.914,3.5-20.284,1-5.884.317-13.949-.526-6.163-1.645-14.034-6.714-29.678Z" transform="translate(58.653 54.257)" fill="#f0c17d" fillRule="evenodd" />
                <path id="Path_19163" data-name="Path 19163" d="M441.63,105.905c-.254,4.066-1.042,7.933-1.573,11.581-.965,6.6-.763,9.743-2.124,13.376-.658,1.75-1.678,3.146-3.368,5.993,0,0,4.682,2.533,5.221,2.61s7.833-1.92,7.833-1.92S451,125.566,451,124.875c0-7.851,4.231-7.026-.254-12.969-1.995-2.643-1.446-6.26-1.356-9.379Z" transform="translate(56.735 117.551)" fill="#f0c17d" fillRule="evenodd" />
                <path id="Path_19164" data-name="Path 19164" d="M437.241,127.211c-2.762,3.61-3.575,5.662-2.556,7.813.566,1.192,2.974,1.7,5.7,1.491a20.039,20.039,0,0,0,6.5-1.434,29.432,29.432,0,0,0,1.538-2.725c.711-4.734,2.159-8.773,3.378-13.4l-.04,11.4,1.037-.112a101.559,101.559,0,0,1,1.768-12.381c.3-1.394.95-2.745,1.035-3.515.379-3.366-2.159-5.6-4.069-8.065-1.281,6.19-1.825,19.187-7.951,22.092A5.514,5.514,0,0,1,437.241,127.211Z" transform="translate(56.283 123.16)" fill="#5e6c78" fillRule="evenodd" />
                <path id="Path_19165" data-name="Path 19165" d="M445.871,63.774h-1.979l.332,18.14c-.536,10.509-2.448,31.162-2.767,41.585-.2,6.7,1.493,10.025,1.281,15.014-.6,14.181,1.251,28.078,1.483,42.712l6.432,6.183c3.017-22.059,7.774-34.1,7.255-46.751-.863-21.079.01-20.76,2.276-33.917,3.493-20.284,1-5.881.314-13.947-.524-6.166-1.646-14.036-6.712-29.681Z" transform="translate(66.998 58.7)" fill="#ffdba7" fillRule="evenodd" />
                <path id="Path_19166" data-name="Path 19166" d="M473.293,48.028c5.575,6.864.471,18.307-3.214,27.534a32.175,32.175,0,0,0-2.421,12.446c0,2.186-.07,3.9.962,5.924,15.011,29.416-19.781,52.124-28.217,31-2.4-6.016-6.3-15.587-1.852-29.569,1.533-4.814,2.386-8.295,2.189-10.593-.177-2.062-.79-4.56-2.585-9.521a17.373,17.373,0,0,1-3.408-10.743c.15-4.443,1.513-8.766,3.3-14.468,1.207-3.832.466-7.19,2.109-9.015C445.547,35.039,466.652,39.851,473.293,48.028Z" transform="translate(56.995 21.724)" fill="#e2d7e3" fillRule="evenodd" />
                <path id="Path_19167" data-name="Path 19167" d="M467.723,60.207c0,2.186.92,3.9,1.955,5.924a35.213,35.213,0,0,1,3.884,10.656c2.014,13.927-1.89,29.773-4.587,56.086-17.262,1.478-29.055-3.657-31.825-6.637-3.488-45.427-2.994-48.519-.179-57.355,1.528-4.812,4.031-9.614,3.834-11.912C446.971,63.575,458.888,64.37,467.723,60.207Z" transform="translate(56.93 49.525)" fill="#473148" fillRule="evenodd" />
                <path id="Path_19168" data-name="Path 19168" d="M448.1,45.4c-4.343,8.295-.327,27.818.18,37.574l9.441,3.059c.893-10.394,3.184-26.864,1.852-36.721-.526-3.887-2.9-7.061-5.757-7.105A6.986,6.986,0,0,0,448.1,45.4Z" transform="translate(73.96 27.477)" fill="#ece1ec" fillRule="evenodd" />
                <path id="Path_19169" data-name="Path 19169" d="M446.744,109.17c-.254,4.069-1.042,7.936-1.573,11.581-.965,6.6-.763,9.743-2.124,13.376-.656,1.75-1.675,3.146-3.368,5.993,0,0,4.685,2.533,5.223,2.61s7.831-1.92,7.831-1.92,3.381-11.979,3.381-12.67c0-7.853,4.228-7.026-.257-12.969-1.995-2.643-1.446-6.26-1.356-9.379Z" transform="translate(64.37 122.426)" fill="#ffdba7" fillRule="evenodd" />
                <path id="Path_19170" data-name="Path 19170" d="M442.356,130.476c-2.765,3.61-3.578,5.662-2.558,7.813.568,1.192,2.974,1.7,5.7,1.491a20.075,20.075,0,0,0,6.5-1.434,29.949,29.949,0,0,0,1.536-2.725c.711-4.734,2.159-8.773,3.378-13.4l-.037,11.406,1.037-.115a100.613,100.613,0,0,1,1.768-12.381c.294-1.394.95-2.745,1.035-3.518.376-3.361-2.159-5.6-4.071-8.063-1.281,6.19-1.825,19.187-7.951,22.092A5.51,5.51,0,0,1,442.356,130.476Z" transform="translate(63.918 128.035)" fill="#473148" fillRule="evenodd" />
                <g id="Group_27464" data-name="Group 27464" transform="translate(396.567 121.245)">
                  <g id="Group_27458" data-name="Group 27458" transform="translate(9.97 49.204)">
                    <path id="Path_19171" data-name="Path 19171" d="M402.952,85.949l5.729-3.328-.149,101.942-5.726,3.328Z" transform="translate(-397.223 -82.621)" fill="#828994" fillRule="evenodd" />
                    <path id="Path_19172" data-name="Path 19172" d="M406.3,85.886l-.146,101.942-5.583-3.222.146-101.942Z" transform="translate(-400.567 -82.558)" fill="#98a0ad" fillRule="evenodd" />
                  </g>
                  <g id="Group_27461" data-name="Group 27461" transform="translate(68.186 38.72)">
                    <g id="Group_27459" data-name="Group 27459" transform="translate(61.715 17.211)">
                      <path id="Path_19173" data-name="Path 19173" d="M451.058,88.648l5.726-3.328-.146,95.709-5.729,3.328Z" transform="translate(-445.329 -85.319)" fill="#828994" fillRule="evenodd" />
                      <path id="Path_19174" data-name="Path 19174" d="M454.4,91.084l-.149,89.478-5.58-3.225.146-89.475Z" transform="translate(-448.671 -81.524)" fill="#98a0ad" fillRule="evenodd" />
                    </g>
                    <g id="Group_27460" data-name="Group 27460">
                      <path id="Path_19175" data-name="Path 19175" d="M426.3,81.744l5.729-3.328-.148,77.011-5.726,3.328Z" transform="translate(-420.574 -78.416)" fill="#787878" fillRule="evenodd" />
                      <path id="Path_19176" data-name="Path 19176" d="M429.646,81.681l-.146,77.011-5.583-3.222.146-77.012Z" transform="translate(-423.917 -78.353)" fill="#98a0ad" fillRule="evenodd" />
                    </g>
                  </g>
                  <g id="Group_27462" data-name="Group 27462" transform="translate(70.884 90.995)">
                    <path id="Path_19177" data-name="Path 19177" d="M427.386,102.711l5.726-3.328-.149,95.712-5.726,3.328Z" transform="translate(-421.657 -99.383)" fill="#828994" fillRule="evenodd" />
                    <path id="Path_19178" data-name="Path 19178" d="M430.728,102.649l-.148,95.712L425,195.136l.146-95.711Z" transform="translate(-424.999 -99.321)" fill="#98a0ad" fillRule="evenodd" />
                  </g>
                  <g id="Group_27463" data-name="Group 27463">
                    <path id="Path_19179" data-name="Path 19179" d="M428.231,123.234l72.44-42.1-.039,13.638-72.437,42.1Z" transform="translate(-349.344 -35.633)" fill="#bac2d1" fillRule="evenodd" />
                    <path id="Path_19180" data-name="Path 19180" d="M547.86,108.39l-72.44,42.1L396.59,104.973l72.423-42.088Z" transform="translate(-396.534 -62.885)" fill="#d4ddec" fillRule="evenodd" />
                    <path id="Path_19181" data-name="Path 19181" d="M475.454,125.281l-.036,13.638-78.85-45.524.039-13.618.017-.01Z" transform="translate(-396.567 -37.679)" fill="#c8d1e0" fillRule="evenodd" />
                  </g>
                </g>
                <g id="Group_27465" data-name="Group 27465" transform="translate(443.854 99.22)">
                  <path id="Path_19182" data-name="Path 19182" d="M431.921,97.585l-.012-29.521,8.177-4.465L440,93a1.259,1.259,0,0,1-.521,1.152l-7.951,4.62C431.854,98.58,431.918,98.154,431.921,97.585Z" transform="translate(-391.647 -39.795)" fill="#331202" fillRule="evenodd" />
                  <path id="Path_19183" data-name="Path 19183" d="M416.414,56.92l1.444-.838a1.668,1.668,0,0,0-.249-.125l-1.446.838A2.592,2.592,0,0,1,416.414,56.92Z" transform="translate(-414.596 -51.204)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19184" data-name="Path 19184" d="M416.287,56.839,417.733,56a1.414,1.414,0,0,0-.212-.072l-1.443.84A1.719,1.719,0,0,1,416.287,56.839Z" transform="translate(-414.722 -51.247)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19185" data-name="Path 19185" d="M416.172,56.79l1.443-.84a1.242,1.242,0,0,0-.155-.035l-1.446.84A1.276,1.276,0,0,1,416.172,56.79Z" transform="translate(-414.816 -51.268)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19186" data-name="Path 19186" d="M416.1,56.763l1.446-.84a1.352,1.352,0,0,0-.135-.012l-1.446.84A1.285,1.285,0,0,1,416.1,56.763Z" transform="translate(-414.896 -51.275)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19187" data-name="Path 19187" d="M416.033,56.751l1.446-.84a.96.96,0,0,0-.12,0l-1.446.84A.954.954,0,0,1,416.033,56.751Z" transform="translate(-414.968 -51.276)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19188" data-name="Path 19188" d="M415.977,56.75l1.446-.84c-.035,0-.072.007-.11.012l-1.443.84C415.9,56.758,415.942,56.753,415.977,56.75Z" transform="translate(-415.032 -51.275)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19189" data-name="Path 19189" d="M415.931,56.755l1.443-.84a.733.733,0,0,0-.1.025l-1.446.838Z" transform="translate(-415.093 -51.268)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19190" data-name="Path 19190" d="M415.886,56.763l1.446-.838a.6.6,0,0,0-.095.032l-1.446.84C415.821,56.785,415.856,56.773,415.886,56.763Z" transform="translate(-415.15 -51.253)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19191" data-name="Path 19191" d="M415.845,56.778l1.446-.84a.763.763,0,0,0-.092.045l-1.444.838C415.785,56.806,415.812,56.791,415.845,56.778Z" transform="translate(-415.204 -51.233)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19192" data-name="Path 19192" d="M415.761,56.795l1.444-.839-.01,0-1.444.84Z" transform="translate(-415.21 -51.207)" fill="#6c3522" fillRule="evenodd" />
                  <path id="Path_19193" data-name="Path 19193" d="M416.169,57.176c0-1.137.81-1.6,1.8-1.027L453.412,76.61a3.956,3.956,0,0,1,1.787,3.1l-.085,29.4c0,.8-.4,1.267-.98,1.272a1.628,1.628,0,0,1-.818-.247L417.875,89.666a3.925,3.925,0,0,1-1.787-3.094Z" transform="translate(-414.708 -51.285)" fill="#571f03" fillRule="evenodd" />
                  <path id="Path_19194" data-name="Path 19194" d="M424.493,70.549c0-.165-.02-.364-.02-.529h0l1.446-.84c0,.165.02.364.02.531a4.676,4.676,0,0,1-.262,1.628,1.713,1.713,0,0,1-.678.908l-1.443.84A2.917,2.917,0,0,0,424.493,70.549Z" transform="translate(-403.556 -31.461)" fill="#93651b" fillRule="evenodd" />
                  <path id="Path_19195" data-name="Path 19195" d="M422.332,68.59l4,2.311c0,.165.02.364.02.529-.005,2.017-.912,3.126-2.029,2.486s-2.014-2.8-2.009-4.817A4.263,4.263,0,0,1,422.332,68.59Z" transform="translate(-405.414 -32.342)" fill="#e89700" fillRule="evenodd" />
                  <path id="Path_19196" data-name="Path 19196" d="M424.269,67.46l4.346,2.509.124.192-2.169,1.26-.122-.192L422.1,68.72Z" transform="translate(-405.73 -34.029)" fill="#cb920e" fillRule="evenodd" />
                  <path id="Path_19197" data-name="Path 19197" d="M423.891,71.7l2.169-1.26,0-1.9L423.9,69.8Z" transform="translate(-403.056 -32.411)" fill="#9d7316" fillRule="evenodd" />
                  <path id="Path_19198" data-name="Path 19198" d="M422.165,67.966l-.1.06-.005,1.9.1.2,4.346,2.509.124-.071,0-1.9-.122-.192Z" transform="translate(-405.796 -33.274)" fill="#ffae00" fillRule="evenodd" />
                  <path id="Path_19199" data-name="Path 19199" d="M432.313,84.849l-.015.007L415.534,63.316l.017-5.8c0-1.137.81-1.6,1.8-1.027l35.442,20.464a3.949,3.949,0,0,1,1.785,3.1l-.013,5.447-16.684,2.316Z" transform="translate(-415.534 -50.773)" fill="#632404" fillRule="evenodd" />
                  <path id="Path_19200" data-name="Path 19200" d="M431.189,71.743l1.444-.84.017-5.447-1.446.84Z" transform="translate(-392.158 -37.02)" fill="#331202" fillRule="evenodd" />
                  <path id="Path_19201" data-name="Path 19201" d="M417.544,58.678l-.072.012-.015,0a.873.873,0,0,0-.262.1l7.953-4.62.008,0a.752.752,0,0,1,.09-.045.887.887,0,0,1,.1-.035l.062-.012.015-.005.022,0c.035,0,.07-.01.11-.012l.042,0h.052l.022,0,.037,0,.027,0,.067.007a1.213,1.213,0,0,1,.16.032l.067.025.065.02c.025.01.05.017.08.03a2.253,2.253,0,0,1,.247.122l35.442,20.464a3.024,3.024,0,0,1,.544.419c.017.017.037.027.055.042l.07.075.057.065.07.07c.047.057.1.117.145.177l.05.067.022.035.047.06c.037.052.07.1.1.157s.062.1.092.152l.007.012.008.012q.037.064.067.127c.03.05.055.1.08.152s.042.092.062.137l.005.01.005.01c.022.052.045.107.065.162s.042.112.062.17a.777.777,0,0,1,.022.082l.012.037.02.062c.02.067.037.137.052.2l.018.122.012.067.01.06a2.29,2.29,0,0,1,.022.317v.032L455.7,82.473v.015l-1.446.84a2.531,2.531,0,0,0-.032-.409l-.01-.065c-.025-.132-.055-.262-.092-.391l-.01-.037a3.955,3.955,0,0,0-.157-.424l-.005-.007a4.1,4.1,0,0,0-.212-.419l-.007-.012c-.075-.132-.16-.259-.247-.384l-.027-.032c-.082-.11-.169-.214-.259-.317l-.062-.062c-.04-.042-.082-.077-.125-.117a2.906,2.906,0,0,0-.539-.419L417.027,59.768a1.267,1.267,0,0,0-1.276-.122l1.443-.84A1.992,1.992,0,0,1,417.544,58.678Z" transform="translate(-415.21 -54.051)" fill="#471a03" fillRule="evenodd" />
                  <path id="Path_19202" data-name="Path 19202" d="M423,57.459l2.167-1.262A2.858,2.858,0,0,0,424,57.847l-2.169,1.262A2.844,2.844,0,0,1,423,57.459Z" transform="translate(-406.139 -50.847)" fill="#331202" fillRule="evenodd" />
                  <path id="Path_19203" data-name="Path 19203" d="M424.414,56.383c-.1-.027-.192-.055-.287-.077s-.174-.04-.259-.057c-.115-.02-.227-.037-.337-.05-.06-.007-.125-.015-.184-.02-.145-.007-.284-.01-.421,0h-.075a3.54,3.54,0,0,0-.469.06l-.115.027a3.081,3.081,0,0,0-.346.1c-.035.012-.072.022-.1.037a3.353,3.353,0,0,0-.416.2l.005,0-.005,0,2.169-1.259.032-.02q.157-.086.322-.157l.06-.02c.035-.015.072-.025.107-.037.055-.02.112-.045.17-.062s.117-.025.175-.037c.04-.01.077-.02.117-.027l.065-.015a3.165,3.165,0,0,1,.384-.045h.017c.025,0,.05,0,.077,0,.1,0,.212-.007.322,0,.032,0,.065.007.1.007l.187.017c.062.007.122.01.182.02s.1.022.152.032c.087.015.172.035.262.055.05.012.095.017.147.032s.092.032.14.047c.137.042.279.09.424.145.052.022.1.032.157.055.032.015.06.032.09.045a7.522,7.522,0,0,1,.793.391l8.307,4.8a9.561,9.561,0,0,1,1.1.753c.12.092.232.2.349.3.214.182.426.357.633.558.022.022.042.047.067.072.052.055.1.112.157.17.157.16.312.319.461.489.115.132.227.272.337.409.032.045.07.085.1.127l.072.087c.147.187.287.381.426.576.115.162.224.329.334.5l.017.032.015.02c.115.18.224.359.332.541s.2.357.3.539l.082.162c.018.035.035.07.052.1.05.095.095.187.142.277.087.184.172.369.249.553.067.15.125.3.185.449l.027.065.025.057c.075.2.15.4.214.6s.14.431.2.646l.013.05.02.077c.052.2.1.4.145.6.055.249.1.5.135.743l-2.167,1.259a13.143,13.143,0,0,0-.279-1.339l-.02-.08a13.922,13.922,0,0,0-.451-1.356.461.461,0,0,0-.027-.062c-.17-.431-.362-.86-.573-1.279-.02-.035-.037-.07-.055-.1q-.333-.647-.725-1.262l-.02-.03q-.393-.606-.83-1.164l-.1-.122q-.381-.471-.8-.9c-.052-.057-.1-.117-.16-.175-.224-.224-.464-.421-.7-.626-.115-.1-.227-.212-.347-.307a9.353,9.353,0,0,0-1.1-.75l-8.307-4.8a7.734,7.734,0,0,0-.79-.391c-.082-.037-.167-.07-.252-.1C424.693,56.476,424.551,56.426,424.414,56.383Z" transform="translate(-406.775 -52.771)" fill="#331202" fillRule="evenodd" />
                  <path id="Path_19204" data-name="Path 19204" d="M426.923,56.268l8.307,4.8a14.171,14.171,0,0,1,6.19,9.554l-2.83-1.633a8.671,8.671,0,0,0-3.7-5.677l-7.646-4.415c-1.917-1.107-3.179-.314-3.735,1.386L420.7,58.656C421.239,55.585,423.839,54.488,426.923,56.268Z" transform="translate(-407.821 -52.017)" fill="#632404" fillRule="evenodd" />
                </g>
                <path id="Path_19205" data-name="Path 19205" d="M449.27,63l-3.159-1.765c-3.423-2.216-4.732-2.491-7.392-1.81A51.973,51.973,0,0,0,429,63.01l1.277,4.223c2.6.032,5.4-1.08,7.983-1.294,2.543-.209,4.246,3.366,6.006,3.144l6.482-.823Z" transform="translate(48.431 52.707)" fill="#ffdba7" fillRule="evenodd" />
                <path id="Path_19206" data-name="Path 19206" d="M436.184,65.1c8.494-2.4,15.216-5.029,23.291-6.851,3.528-.8,7.18-1.147,10.992-1.77,3.667,6.829,2.8,10.538-2.234,12.3-7.1,2.481-24.084,3.515-31.421,4.086Z" transform="translate(59.153 48.789)" fill="#ece1ec" fillRule="evenodd" />
                <g id="Group_27470" data-name="Group 27470" transform="translate(417.384 129.974)">
                  <g id="Group_27468" data-name="Group 27468" transform="translate(3.314 16.748)">
                    <g id="Group_27466" data-name="Group 27466">
                      <path id="Path_19207" data-name="Path 19207" d="M460.019,83.571l-.01,3.356q-17.82,10.355-35.634,20.708l.01-3.356Z" transform="translate(-379.177 -57.475)" fill="#8a8c8b" fillRule="evenodd" />
                      <path id="Path_19208" data-name="Path 19208" d="M441.884,73.1l45.2,26.1q-17.82,10.355-35.634,20.708l-45.2-26.093Q424.073,83.459,441.884,73.1Z" transform="translate(-406.24 -73.104)" fill="#a1a3a2" fillRule="evenodd" />
                      <path id="Path_19209" data-name="Path 19209" d="M451.454,107.5l-.01,3.355-45.2-26.094.01-3.355Z" transform="translate(-406.246 -60.7)" fill="#331202" fillRule="evenodd" />
                    </g>
                    <path id="Path_19210" data-name="Path 19210" d="M423.117,76.564l44.328,25.762-14.735,8.506-44.326-25.76Z" transform="translate(-403.053 -67.938)" fill="#878783" fillRule="evenodd" />
                    <g id="Group_27467" data-name="Group 27467" transform="translate(7.354 9.244)">
                      <path id="Path_19211" data-name="Path 19211" d="M430.59,87.09a.349.349,0,0,1-.314,0l-.925-.539c-.09-.05-.09-.132,0-.182l.932-.536a.339.339,0,0,1,.317,0l.925.536a.1.1,0,0,1,0,.182Z" transform="translate(-379.202 -63.399)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19212" data-name="Path 19212" d="M428.862,86.087a.349.349,0,0,1-.314,0l-.927-.539c-.085-.05-.077-.13.01-.18l.932-.539a.341.341,0,0,1,.307,0l.925.536a.1.1,0,0,1,0,.184Z" transform="translate(-381.775 -64.895)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19213" data-name="Path 19213" d="M429.428,86.416a.333.333,0,0,1-.307,0l-.925-.539a.1.1,0,0,1,0-.184l.932-.536a.349.349,0,0,1,.314,0l.927.536c.087.052.08.127-.01.18Z" transform="translate(-380.921 -64.399)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19214" data-name="Path 19214" d="M430,86.75a.326.326,0,0,1-.307,0l-.935-.541c-.08-.047-.08-.127.01-.179l.932-.539a.358.358,0,0,1,.307,0l.935.541c.085.052.077.127-.01.179Z" transform="translate(-380.067 -63.902)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19215" data-name="Path 19215" d="M429.517,85.71l1.461.848c.135.077.132.2,0,.274l-1.469.848a.528.528,0,0,1-.481.005l-1.458-.848a.15.15,0,0,1,0-.284l1.468-.848A.523.523,0,0,1,429.517,85.71Z" transform="translate(-381.916 -63.615)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19216" data-name="Path 19216" d="M431.142,87.411a.349.349,0,0,1-.307,0l-.935-.544c-.087-.05-.077-.127.01-.177l.932-.539a.333.333,0,0,1,.307-.005l.932.544c.082.045.08.127-.008.177Z" transform="translate(-378.372 -62.918)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19217" data-name="Path 19217" d="M430.448,87.009l.932-.541a.365.365,0,0,1,.314,0l.927.541c.087.05.087.13,0,.18l-.932.541a.348.348,0,0,1-.314,0l-.925-.541C430.361,87.139,430.361,87.059,430.448,87.009Z" transform="translate(-377.561 -62.446)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19218" data-name="Path 19218" d="M430.382,86.214l1.469.853c.125.072.132.194,0,.274l-1.469.848a.539.539,0,0,1-.479,0l-1.471-.853c-.125-.072-.125-.2.01-.279l1.471-.848A.508.508,0,0,1,430.382,86.214Z" transform="translate(-380.615 -62.86)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19219" data-name="Path 19219" d="M429.133,88.486a.523.523,0,0,1-.471,0l-1.461-.848c-.132-.077-.142-.2-.005-.279l1.469-.848a.544.544,0,0,1,.489,0l1.458.848c.127.072.127.2-.01.279Z" transform="translate(-382.467 -62.415)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19220" data-name="Path 19220" d="M429.3,87.561l1.471-.85a.513.513,0,0,1,.474,0l1.458.85c.132.077.14.2.007.277l-1.471.85a.544.544,0,0,1-.489,0l-1.456-.85C429.157,87.76,429.164,87.638,429.3,87.561Z" transform="translate(-379.337 -62.118)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19221" data-name="Path 19221" d="M428.1,87.888l1.471-.848a.523.523,0,0,1,.479,0l2.239,1.3a.152.152,0,0,1,0,.284l-1.471.848a.531.531,0,0,1-.479,0l-2.239-1.3C427.969,88.085,427.969,87.965,428.1,87.888Z" transform="translate(-381.116 -61.629)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19222" data-name="Path 19222" d="M428.6,85.18l1.461.845c.135.077.132.2,0,.274l-1.468.85a.523.523,0,0,1-.479,0l-1.461-.848c-.135-.077-.125-.2.01-.279l1.469-.845A.494.494,0,0,1,428.6,85.18Z" transform="translate(-383.276 -64.406)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19223" data-name="Path 19223" d="M428.239,85.982l1.461.845c.135.08.125.2-.012.279l-1.469.85a.508.508,0,0,1-.471,0l-1.458-.85c-.135-.077-.142-.2-.007-.279l1.468-.845A.544.544,0,0,1,428.239,85.982Z" transform="translate(-383.829 -63.206)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19224" data-name="Path 19224" d="M422.978,86.258l-1.461-.848c-.135-.08-.125-.2.01-.279L423,84.284a.508.508,0,0,1,.471,0l1.458.848c.135.077.142.2.007.279l-1.471.848A.539.539,0,0,1,422.978,86.258Z" transform="translate(-390.942 -65.741)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19225" data-name="Path 19225" d="M427.969,88.878a.531.531,0,0,1-.479,0l-1.458-.848a.148.148,0,0,1,0-.282L427.5,86.9a.516.516,0,0,1,.479,0l1.461.848c.132.08.132.2,0,.274Z" transform="translate(-384.208 -61.829)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19226" data-name="Path 19226" d="M426.191,88.361a.523.523,0,0,1,.471,0l1.466.85a.146.146,0,0,1,0,.274l-1.476.853a.544.544,0,0,1-.474,0l-1.469-.853a.148.148,0,0,1,0-.274Z" transform="translate(-386.168 -59.651)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19227" data-name="Path 19227" d="M425.319,87.852a.547.547,0,0,1,.479,0l1.461.85a.144.144,0,0,1,0,.272l-1.476.855a.528.528,0,0,1-.474,0l-1.459-.85a.147.147,0,0,1-.007-.277Z" transform="translate(-387.471 -60.407)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19228" data-name="Path 19228" d="M426.94,88.277l1.468-.848a.468.468,0,0,1,.419-.032l2.982,1.733c.1.06.087.17-.047.247l-1.471.848a.484.484,0,0,1-.434.032l-2.982-1.733C426.773,88.464,426.808,88.354,426.94,88.277Z" transform="translate(-382.887 -61.061)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19229" data-name="Path 19229" d="M425.484,88.553l1.471-.848a.532.532,0,0,1,.486.01l4.026,2.339c.135.077.15.207.015.284l-1.471.848a.552.552,0,0,1-.5,0l-4.024-2.339C425.349,88.76,425.349,88.63,425.484,88.553Z" transform="translate(-385.025 -60.626)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19230" data-name="Path 19230" d="M427.717,85.42a.34.34,0,0,1-.307,0l-.935-.541c-.08-.045-.08-.13.007-.179l.932-.536a.349.349,0,0,1,.307,0l.935.541c.077.045.077.13-.01.179Z" transform="translate(-383.484 -65.888)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19231" data-name="Path 19231" d="M428.289,85.754a.341.341,0,0,1-.307,0l-.935-.544c-.085-.047-.077-.127.01-.177l.932-.536a.333.333,0,0,1,.307,0l.935.541c.077.045.077.13-.01.177Z" transform="translate(-382.63 -65.392)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19232" data-name="Path 19232" d="M427.142,85.087a.333.333,0,0,1-.307,0l-.935-.541c-.077-.047-.077-.13.01-.18l.932-.536a.349.349,0,0,1,.307,0l.932.541c.087.05.08.13-.007.18Z" transform="translate(-384.339 -66.385)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19233" data-name="Path 19233" d="M426,84.425a.37.37,0,0,1-.317,0l-.925-.539c-.087-.052-.08-.127.008-.179l.935-.536a.321.321,0,0,1,.3,0l.927.536c.087.052.085.132,0,.184Z" transform="translate(-386.048 -67.378)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19234" data-name="Path 19234" d="M426.568,84.751a.326.326,0,0,1-.307.005l-.925-.536a.1.1,0,0,1,0-.184l.932-.536a.349.349,0,0,1,.314,0l.927.536c.085.052.077.13-.01.18Z" transform="translate(-385.194 -66.882)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19235" data-name="Path 19235" d="M422.026,82.115a.349.349,0,0,1-.307.005l-.932-.544c-.087-.05-.077-.127.007-.177l.932-.541a.346.346,0,0,1,.309,0l.932.544c.077.045.077.127-.008.177Z" transform="translate(-391.979 -70.825)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19236" data-name="Path 19236" d="M422.6,82.451a.349.349,0,0,1-.314,0l-.927-.539a.1.1,0,0,1,0-.182l.932-.541a.341.341,0,0,1,.314,0l.927.539c.087.05.087.132,0,.182Z" transform="translate(-391.127 -70.331)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19237" data-name="Path 19237" d="M423.178,82.783a.333.333,0,0,1-.307,0l-.935-.544c-.077-.045-.077-.127.01-.177l.932-.536a.341.341,0,0,1,.307-.007l.935.544c.08.045.08.127-.008.177Z" transform="translate(-390.258 -69.825)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19238" data-name="Path 19238" d="M422.3,81.518l1.461.848c.135.08.142.2.005.279l-1.469.848a.529.529,0,0,1-.489,0l-1.458-.848c-.127-.072-.125-.2.007-.279l1.471-.848A.523.523,0,0,1,422.3,81.518Z" transform="translate(-392.675 -69.869)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19239" data-name="Path 19239" d="M423.733,83.107a.341.341,0,0,1-.307,0l-.935-.546c-.077-.045-.077-.127.01-.177l.93-.536a.338.338,0,0,1,.309,0l.932.541c.087.05.077.13-.01.177Z" transform="translate(-389.43 -69.344)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19240" data-name="Path 19240" d="M421.04,81.8l1.466.853c.125.075.125.2-.01.279l-1.468.848a.528.528,0,0,1-.474,0l-1.469-.853a.146.146,0,0,1,0-.274l1.468-.848A.55.55,0,0,1,421.04,81.8Z" transform="translate(-394.57 -69.449)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19241" data-name="Path 19241" d="M424.276,83.422a.349.349,0,0,1-.307,0l-.932-.544c-.08-.045-.077-.127.007-.177l.932-.539a.349.349,0,0,1,.307,0l.935.544c.077.045.077.127-.01.177Z" transform="translate(-388.617 -68.871)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19242" data-name="Path 19242" d="M423.207,82.041l1.461.85c.132.077.13.2,0,.274l-1.469.848a.526.526,0,0,1-.484,0l-1.458-.848c-.132-.075-.122-.2.01-.277l1.468-.85A.528.528,0,0,1,423.207,82.041Z" transform="translate(-391.332 -69.088)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19243" data-name="Path 19243" d="M419.386,84.173l-1.468-.855c-.125-.072-.125-.2.01-.277l1.469-.85a.528.528,0,0,1,.474,0l1.466.855a.144.144,0,0,1,0,.272l-1.469.85A.544.544,0,0,1,419.386,84.173Z" transform="translate(-396.312 -68.864)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19244" data-name="Path 19244" d="M421.942,82.32l1.461.848a.151.151,0,0,1,0,.284l-1.471.85a.523.523,0,0,1-.479-.007l-1.461-.848c-.135-.075-.132-.194,0-.272l1.469-.85A.536.536,0,0,1,421.942,82.32Z" transform="translate(-393.228 -68.669)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19245" data-name="Path 19245" d="M417.943,84.456l-1.461-.85a.149.149,0,0,1,0-.282l1.471-.848a.523.523,0,0,1,.479,0l1.458.848c.135.077.135.194,0,.274l-1.471.848A.523.523,0,0,1,417.943,84.456Z" transform="translate(-398.464 -68.436)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19246" data-name="Path 19246" d="M424.109,82.567l1.461.848a.145.145,0,0,1,0,.274l-1.468.848a.544.544,0,0,1-.481,0l-1.458-.848a.151.151,0,0,1,0-.284l1.471-.848A.523.523,0,0,1,424.109,82.567Z" transform="translate(-389.991 -68.308)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19247" data-name="Path 19247" d="M420.28,84.69l-1.458-.85c-.135-.075-.127-.2.007-.277l1.471-.848a.508.508,0,0,1,.471,0l1.461.848c.132.077.14.2.005.277l-1.468.85A.544.544,0,0,1,420.28,84.69Z" transform="translate(-394.97 -68.083)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19248" data-name="Path 19248" d="M418.843,84.979l-1.469-.853c-.122-.072-.122-.2.01-.279L418.855,83a.536.536,0,0,1,.474,0l1.466.853a.146.146,0,0,1,0,.274l-1.471.848A.528.528,0,0,1,418.843,84.979Z" transform="translate(-397.12 -67.655)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19249" data-name="Path 19249" d="M425,83.086l1.458.85c.135.077.142.2.007.277L425,85.06a.544.544,0,0,1-.489,0l-1.461-.848c-.125-.072-.125-.2.01-.277l1.471-.85A.523.523,0,0,1,425,83.086Z" transform="translate(-388.648 -67.528)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19250" data-name="Path 19250" d="M423.736,83.364l1.468.853c.127.072.125.2-.01.279l-1.468.848a.515.515,0,0,1-.471,0l-1.468-.853a.144.144,0,0,1,0-.274l1.466-.848A.545.545,0,0,1,423.736,83.364Z" transform="translate(-390.543 -67.108)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19251" data-name="Path 19251" d="M419.74,85.5l-1.461-.848c-.135-.077-.125-.2.01-.277l1.471-.85a.523.523,0,0,1,.471,0l1.458.85c.135.077.142.2.008.277l-1.471.848A.524.524,0,0,1,419.74,85.5Z" transform="translate(-395.778 -66.875)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19252" data-name="Path 19252" d="M425.906,83.61l1.458.85c.132.077.132.2,0,.272l-1.471.85a.523.523,0,0,1-.479,0l-1.461-.85c-.135-.077-.125-.2.01-.277l1.469-.85A.513.513,0,0,1,425.906,83.61Z" transform="translate(-387.305 -66.748)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19253" data-name="Path 19253" d="M424.638,83.888l1.461.85c.135.077.132.2,0,.282l-1.468.848a.514.514,0,0,1-.481,0l-1.458-.848c-.135-.077-.135-.194,0-.274l1.471-.848A.523.523,0,0,1,424.638,83.888Z" transform="translate(-389.201 -66.328)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19254" data-name="Path 19254" d="M418.808,84.07a.528.528,0,0,1,.474,0l1.461.848c.132.08.132.207.007.279l-1.478.855a.536.536,0,0,1-.481-.007l-1.459-.848c-.135-.075-.125-.2,0-.274Z" transform="translate(-397.193 -66.058)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19255" data-name="Path 19255" d="M426.807,84.136l1.458.848a.143.143,0,0,1,0,.272l-1.471.85a.539.539,0,0,1-.479,0l-1.461-.85a.149.149,0,0,1,0-.282l1.471-.848A.516.516,0,0,1,426.807,84.136Z" transform="translate(-385.962 -65.967)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19256" data-name="Path 19256" d="M425.542,84.413l1.461.85c.132.077.122.2-.01.279l-1.471.845a.494.494,0,0,1-.471,0l-1.461-.845c-.132-.08-.14-.2-.008-.279l1.471-.85A.544.544,0,0,1,425.542,84.413Z" transform="translate(-387.858 -65.549)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19257" data-name="Path 19257" d="M421.543,86.547l-1.468-.853c-.127-.072-.125-.2.01-.277l1.466-.85a.528.528,0,0,1,.474,0l1.469.853a.145.145,0,0,1,0,.274l-1.469.848A.539.539,0,0,1,421.543,86.547Z" transform="translate(-393.092 -65.315)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19258" data-name="Path 19258" d="M427.7,84.654l1.458.848c.132.077.14.2.008.279l-1.471.848a.544.544,0,0,1-.489,0l-1.461-.848c-.125-.072-.122-.2.01-.279l1.471-.848A.528.528,0,0,1,427.7,84.654Z" transform="translate(-384.619 -65.186)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19259" data-name="Path 19259" d="M423.877,86.781l-1.461-.848c-.132-.08-.122-.2.01-.279l1.468-.848a.513.513,0,0,1,.474,0l1.461.848c.132.08.132.2,0,.274l-1.471.848A.528.528,0,0,1,423.877,86.781Z" transform="translate(-389.597 -64.96)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19260" data-name="Path 19260" d="M426.434,84.931l1.468.855c.125.072.125.2-.01.279l-1.471.848a.523.523,0,0,1-.471,0l-1.466-.853c-.127-.075-.135-.2,0-.274l1.471-.848A.531.531,0,0,1,426.434,84.931Z" transform="translate(-386.514 -64.768)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19261" data-name="Path 19261" d="M422.436,87.066l-1.458-.848c-.135-.077-.127-.2.01-.279l1.468-.848a.508.508,0,0,1,.471,0l1.461.848c.132.08.142.2.005.279l-1.469.848A.529.529,0,0,1,422.436,87.066Z" transform="translate(-391.75 -64.535)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19262" data-name="Path 19262" d="M424.782,87.306l-1.461-.848c-.135-.077-.135-.2,0-.284l1.469-.848a.528.528,0,0,1,.481,0l1.458.848c.127.075.135.2,0,.274l-1.469.848A.531.531,0,0,1,424.782,87.306Z" transform="translate(-388.255 -64.181)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19263" data-name="Path 19263" d="M423.337,87.592l-1.458-.85a.15.15,0,0,1,0-.282l1.471-.848a.521.521,0,0,1,.481,0l1.461.848c.132.077.13.2,0,.272l-1.469.85A.534.534,0,0,1,423.337,87.592Z" transform="translate(-390.408 -63.755)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19264" data-name="Path 19264" d="M425.672,87.824l-1.459-.848c-.125-.072-.125-.2.01-.277l1.469-.85a.541.541,0,0,1,.476,0l1.456.85c.135.077.14.2.007.277l-1.468.848A.534.534,0,0,1,425.672,87.824Z" transform="translate(-386.911 -63.4)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19265" data-name="Path 19265" d="M424.24,88.116l-1.466-.855c-.127-.072-.127-.2.007-.277l1.471-.848a.515.515,0,0,1,.471,0l1.469.853c.127.072.132.194,0,.274l-1.469.848A.544.544,0,0,1,424.24,88.116Z" transform="translate(-389.064 -62.974)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19266" data-name="Path 19266" d="M427.057,88.344a.513.513,0,0,1-.481,0l-1.459-.845c-.135-.077-.127-.2.01-.279l1.466-.85a.521.521,0,0,1,.474,0l1.461.848c.135.077.132.2,0,.274Z" transform="translate(-385.569 -62.619)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19267" data-name="Path 19267" d="M425.133,88.634l-1.461-.848c-.132-.077-.122-.2.01-.279l1.471-.848a.513.513,0,0,1,.474,0l1.456.848c.135.077.142.2.007.279l-1.466.848A.549.549,0,0,1,425.133,88.634Z" transform="translate(-387.722 -62.194)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19268" data-name="Path 19268" d="M419.728,84.6a.589.589,0,0,1,.529.032l10.72,6.23c.157.092.179.232.052.3l-1.476.855a.6.6,0,0,1-.526-.032L418.3,85.764c-.157-.092-.179-.234-.052-.307Z" transform="translate(-395.797 -65.247)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19269" data-name="Path 19269" d="M426.527,89.161a.528.528,0,0,1-.481,0l-1.461-.85c-.13-.075-.122-.2.01-.277l1.471-.85a.528.528,0,0,1,.474,0l1.458.85c.132.077.132.2,0,.272Z" transform="translate(-386.358 -61.401)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19270" data-name="Path 19270" d="M424.411,87.325a.523.523,0,0,1,.471,0l1.461.848a.145.145,0,0,1,0,.274l-1.476.855a.544.544,0,0,1-.474,0l-1.459-.85c-.135-.077-.125-.2,0-.274Z" transform="translate(-388.831 -61.198)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19271" data-name="Path 19271" d="M427.335,85.456l1.461.848a.149.149,0,0,1,0,.282l-1.469.85a.521.521,0,0,1-.481-.005l-1.461-.848c-.13-.077-.13-.2,0-.274l1.471-.848A.523.523,0,0,1,427.335,85.456Z" transform="translate(-385.171 -63.987)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19272" data-name="Path 19272" d="M422.083,85.738l-1.461-.848a.149.149,0,0,1,0-.282l1.469-.848a.521.521,0,0,1,.481,0l1.458.848a.145.145,0,0,1,0,.274l-1.471.85A.547.547,0,0,1,422.083,85.738Z" transform="translate(-392.283 -66.522)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19273" data-name="Path 19273" d="M420.642,86.025l-1.461-.85c-.135-.075-.132-.2,0-.282l1.468-.848a.521.521,0,0,1,.481,0l1.458.85c.135.077.135.2,0,.274l-1.466.848A.536.536,0,0,1,420.642,86.025Z" transform="translate(-394.436 -66.097)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19274" data-name="Path 19274" d="M421.18,85.213l-1.461-.848c-.132-.077-.125-.2.01-.279l1.471-.848a.508.508,0,0,1,.471,0l1.461.848c.132.077.132.2,0,.274l-1.466.85A.541.541,0,0,1,421.18,85.213Z" transform="translate(-393.626 -67.302)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19275" data-name="Path 19275" d="M422.844,82.846l1.458.848c.135.077.125.2-.007.279l-1.469.848a.513.513,0,0,1-.474,0l-1.461-.848c-.132-.08-.14-.2,0-.279l1.468-.848A.544.544,0,0,1,422.844,82.846Z" transform="translate(-391.886 -67.888)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19276" data-name="Path 19276" d="M417.732,79.876l3.116,1.81c.1.06.077.172-.057.252l-1.471.848a.469.469,0,0,1-.424.027L415.78,81c-.1-.06-.087-.17.05-.247l1.469-.85A.491.491,0,0,1,417.732,79.876Z" transform="translate(-399.465 -72.293)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19277" data-name="Path 19277" d="M419.242,80.753l1.468.853c.125.072.122.2-.01.279l-1.469.845a.52.52,0,0,1-.474,0l-1.466-.853a.143.143,0,0,1,0-.272l1.469-.848A.529.529,0,0,1,419.242,80.753Z" transform="translate(-397.255 -71.009)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19278" data-name="Path 19278" d="M415.377,81.871l-1.466-.853a.146.146,0,0,1,0-.274l1.473-.853a.528.528,0,0,1,.474,0l1.469.853a.147.147,0,0,1,0,.274l-1.476.853A.544.544,0,0,1,415.377,81.871Z" transform="translate(-402.296 -72.297)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19279" data-name="Path 19279" d="M414.412,82.212l.229,0,1.648.957-.01.133-.694.4h-.222l-1.648-.958,0-.127Z" transform="translate(-402.447 -68.756)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19280" data-name="Path 19280" d="M415.076,81.959l1.639.954.007.132-.7.407-.229,0-1.641-.953v-.127l.7-.408Z" transform="translate(-401.798 -69.125)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19281" data-name="Path 19281" d="M415.237,82.691l.226,0,1.651.958-.01.133-.694.4h-.222l-1.648-.958v-.128Z" transform="translate(-401.215 -68.04)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19282" data-name="Path 19282" d="M419.378,84.193l-4.48-2.6c-.15-.087-.165-.224-.037-.3l1.476-.853a.593.593,0,0,1,.514.022l4.48,2.605c.157.09.165.224.037.3l-1.476.853A.57.57,0,0,1,419.378,84.193Z" transform="translate(-400.867 -71.465)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19283" data-name="Path 19283" d="M418.484,83.648l-1.461-.85c-.135-.077-.125-.2.01-.277l1.469-.85a.508.508,0,0,1,.471,0l1.461.85c.135.075.135.194,0,.272l-1.468.85A.531.531,0,0,1,418.484,83.648Z" transform="translate(-397.653 -69.643)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19284" data-name="Path 19284" d="M413.929,81.84l1.468-.848a.513.513,0,0,1,.474,0c1.882,1.092,3.735,2.172,5.595,3.251.132.075.14.2.008.279L420,85.371a.565.565,0,0,1-.491,0l-5.595-3.254C413.794,82.045,413.8,81.918,413.929,81.84Z" transform="translate(-402.281 -70.655)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19285" data-name="Path 19285" d="M421.413,81l1.459.848a.145.145,0,0,1,0,.274l-1.469.848a.544.544,0,0,1-.481,0l-1.461-.848a.152.152,0,0,1,0-.284l1.468-.848A.534.534,0,0,1,421.413,81Z" transform="translate(-394.018 -70.649)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19286" data-name="Path 19286" d="M419.741,80.786a.349.349,0,0,1-.314,0l-.927-.539a.1.1,0,0,1,0-.182l.932-.539a.349.349,0,0,1,.314,0l.925.539a.1.1,0,0,1,0,.182Z" transform="translate(-395.399 -72.812)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19287" data-name="Path 19287" d="M420.305,81.114a.333.333,0,0,1-.307,0l-.925-.541c-.087-.047-.085-.13,0-.18l.932-.539a.354.354,0,0,1,.317,0l.925.539c.087.05.08.127-.01.177Z" transform="translate(-394.544 -72.315)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19288" data-name="Path 19288" d="M418.643,79.389l3.862,2.244c.135.077.142.2.008.279l-1.471.848a.539.539,0,0,1-.486,0l-3.862-2.244c-.127-.075-.127-.2.01-.279l1.469-.848A.539.539,0,0,1,418.643,79.389Z" transform="translate(-398.142 -73.044)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19289" data-name="Path 19289" d="M420.51,80.475l1.458.848c.135.077.135.2,0,.274l-1.471.848a.523.523,0,0,1-.479,0l-1.461-.848c-.135-.08-.125-.2.01-.279l1.471-.848A.508.508,0,0,1,420.51,80.475Z" transform="translate(-395.362 -71.429)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19290" data-name="Path 19290" d="M420.145,81.278l1.458.848c.135.077.127.2-.007.277l-1.471.85a.508.508,0,0,1-.471,0L418.2,82.4c-.135-.075-.142-.2-.008-.277l1.471-.848A.539.539,0,0,1,420.145,81.278Z" transform="translate(-395.915 -70.23)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19291" data-name="Path 19291" d="M412.592,80.455a.539.539,0,0,1,.479.005l1.461.848a.145.145,0,0,1,0,.274l-1.478.853a.515.515,0,0,1-.471,0l-1.458-.848c-.135-.077-.135-.207-.007-.279Z" transform="translate(-406.474 -71.451)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19292" data-name="Path 19292" d="M415.135,78.109a.354.354,0,0,1-.317,0l-.925-.541c-.09-.047-.087-.13,0-.182l.932-.536a.341.341,0,0,1,.314,0l.927.536c.085.052.085.135,0,.182Z" transform="translate(-402.281 -76.812)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19293" data-name="Path 19293" d="M415.7,78.438a.333.333,0,0,1-.307,0l-.932-.544c-.08-.045-.08-.127.007-.18l.932-.536a.333.333,0,0,1,.307-.005l.935.541c.085.052.077.13-.01.18Z" transform="translate(-401.425 -76.315)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19294" data-name="Path 19294" d="M416.419,78.393l-1.476.855a.544.544,0,0,1-.474,0L413,78.393a.145.145,0,0,1,0-.272l1.479-.855a.544.544,0,0,1,.474,0l1.466.855A.145.145,0,0,1,416.419,78.393Z" transform="translate(-403.651 -76.213)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19295" data-name="Path 19295" d="M415.4,77.789a.544.544,0,0,1,.474,0l1.461.85a.15.15,0,0,1,.005.279l-1.478.853a.544.544,0,0,1-.481,0l-1.461-.848a.146.146,0,0,1,0-.274Z" transform="translate(-402.29 -75.433)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19296" data-name="Path 19296" d="M414.525,78.3a.523.523,0,0,1,.479,0l1.461.848a.149.149,0,0,1,0,.282L415,80.27a.519.519,0,0,1-.484,0l-1.458-.848c-.132-.077-.132-.194,0-.272Z" transform="translate(-403.583 -74.687)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19297" data-name="Path 19297" d="M416.974,78.894Q415.051,80,413.134,81.11a.539.539,0,0,1-.471,0l-1.468-.853a.146.146,0,0,1,0-.274q1.915-1.107,3.837-2.216a.544.544,0,0,1,.481,0l1.468.855C417.108,78.69,417.108,78.817,416.974,78.894Z" transform="translate(-406.353 -75.472)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19298" data-name="Path 19298" d="M413.563,80.815l-1.461-.85c-.132-.075-.125-.2,0-.272l1.478-.853a.515.515,0,0,1,.471,0l1.458.848c.135.08.135.207.008.277l-1.479.855A.539.539,0,0,1,413.563,80.815Z" transform="translate(-405.001 -73.868)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19299" data-name="Path 19299" d="M412.618,81.362l-1.461-.85c-.132-.077-.125-.2.01-.279l1.469-.845a.5.5,0,0,1,.474,0l1.461.845c.132.077.14.2.005.279l-1.471.85A.555.555,0,0,1,412.618,81.362Z" transform="translate(-406.41 -73.054)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19300" data-name="Path 19300" d="M409.291,81.067l3.842-2.219a.534.534,0,0,1,.469,0l1.468.855a.145.145,0,0,1,0,.274q-1.922,1.107-3.839,2.216a.544.544,0,0,1-.474,0l-1.468-.853A.147.147,0,0,1,409.291,81.067Z" transform="translate(-409.196 -73.851)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19301" data-name="Path 19301" d="M411.69,79.932a.528.528,0,0,1,.474,0l1.458.85c.132.077.132.2.007.277l-1.478.853a.544.544,0,0,1-.481,0l-1.461-.848c-.132-.077-.122-.2,0-.274Z" transform="translate(-407.826 -72.237)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19302" data-name="Path 19302" d="M414.469,81.343l-1.458-.848c-.132-.08-.132-.2-.008-.279l1.478-.853a.539.539,0,0,1,.479,0l1.461.848c.135.077.125.2,0,.274l-1.474.853A.533.533,0,0,1,414.469,81.343Z" transform="translate(-403.649 -73.082)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19303" data-name="Path 19303" d="M415.423,80.793l-1.461-.848c-.132-.077-.14-.2,0-.277l1.466-.85a.544.544,0,0,1,.489,0l1.461.85c.132.077.125.2-.01.277l-1.469.848A.513.513,0,0,1,415.423,80.793Z" transform="translate(-402.232 -73.901)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19304" data-name="Path 19304" d="M416.815,79.343l1.466.85c.127.075.127.2-.007.279l-1.471.848a.523.523,0,0,1-.471,0l-1.469-.853a.145.145,0,0,1,0-.274l1.468-.848A.536.536,0,0,1,416.815,79.343Z" transform="translate(-400.879 -73.115)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19305" data-name="Path 19305" d="M416.785,78.316l1.458.848a.145.145,0,0,1,0,.274l-1.471.848a.521.521,0,0,1-.481,0l-1.458-.848a.149.149,0,0,1,0-.282l1.469-.85A.544.544,0,0,1,416.785,78.316Z" transform="translate(-400.929 -74.653)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19306" data-name="Path 19306" d="M418.594,80.118a.349.349,0,0,1-.307,0l-.935-.544c-.08-.045-.08-.127.005-.177l.932-.538a.346.346,0,0,1,.309-.005l.935.544c.077.045.077.127-.01.177Z" transform="translate(-397.106 -73.805)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19307" data-name="Path 19307" d="M416.272,78.771a.349.349,0,0,1-.307,0l-.935-.544c-.077-.045-.077-.127.01-.177l.932-.539a.341.341,0,0,1,.307-.007l.932.546c.077.045.077.127-.007.177Z" transform="translate(-400.57 -75.818)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19308" data-name="Path 19308" d="M416.847,79.1a.355.355,0,0,1-.309,0l-.932-.544c-.087-.05-.077-.13.007-.177l.932-.541a.354.354,0,0,1,.309,0l.932.546c.08.045.08.127-.005.177Z" transform="translate(-399.717 -75.321)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19309" data-name="Path 19309" d="M417.445,79.451a.333.333,0,0,1-.307,0l-.925-.539a.1.1,0,0,1,0-.182l.932-.538a.349.349,0,0,1,.314,0l.927.538c.085.05.077.127-.01.177Z" transform="translate(-398.816 -74.798)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19310" data-name="Path 19310" d="M418.019,79.785a.333.333,0,0,1-.307,0l-.935-.544c-.077-.047-.077-.127.01-.177l.932-.538a.349.349,0,0,1,.307-.005l.932.544c.087.05.08.127-.008.177Z" transform="translate(-397.961 -74.301)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19311" data-name="Path 19311" d="M417.688,78.836l1.468.853c.127.075.135.2,0,.274l-1.471.85a.539.539,0,0,1-.479,0l-1.468-.855c-.125-.072-.125-.2.01-.277l1.466-.85A.528.528,0,0,1,417.688,78.836Z" transform="translate(-399.565 -73.873)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19312" data-name="Path 19312" d="M413.571,81.724l.229-.006,1.648.958-.007.131-.7.4h-.219l-1.651-.958,0-.125Z" transform="translate(-403.703 -69.485)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19313" data-name="Path 19313" d="M413.5,80.982a.539.539,0,0,1,.471,0l1.469.853a.147.147,0,0,1,0,.274l-1.478.853a.545.545,0,0,1-.474,0l-1.466-.853a.146.146,0,0,1,0-.274Z" transform="translate(-405.122 -70.665)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19314" data-name="Path 19314" d="M414.434,82.416l-1.469-.853c-.127-.072-.125-.2.01-.277l1.468-.85a.523.523,0,0,1,.471,0l1.469.853a.145.145,0,0,1,0,.274l-1.469.848A.528.528,0,0,1,414.434,82.416Z" transform="translate(-403.706 -71.483)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19315" data-name="Path 19315" d="M413.526,81.889l-1.458-.848c-.135-.077-.135-.2,0-.284l1.468-.848a.523.523,0,0,1,.479,0l1.461.848c.135.08.132.2,0,.274l-1.469.848A.528.528,0,0,1,413.526,81.889Z" transform="translate(-405.059 -72.269)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19316" data-name="Path 19316" d="M427.051,88.858a.518.518,0,0,1,.469,0l1.461.85c.135.077.135.2.007.277l-1.478.853a.539.539,0,0,1-.479,0l-1.461-.848c-.135-.077-.125-.2,0-.272Z" transform="translate(-384.892 -58.909)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19317" data-name="Path 19317" d="M417.01,83.023a.524.524,0,0,1,.479,0l1.458.848a.143.143,0,0,1,0,.272L417.468,85A.523.523,0,0,1,417,85l-1.461-.848c-.135-.08-.132-.207-.005-.279Z" transform="translate(-399.879 -67.62)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19318" data-name="Path 19318" d="M417.91,83.545a.539.539,0,0,1,.471,0l1.466.853a.146.146,0,0,1,0,.274l-1.479.853a.539.539,0,0,1-.471,0l-1.469-.853a.146.146,0,0,1,0-.274Z" transform="translate(-398.536 -66.838)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19319" data-name="Path 19319" d="M424.852,83.758a.349.349,0,0,1-.307,0l-.935-.541c-.077-.045-.075-.13.01-.18l.932-.539a.341.341,0,0,1,.307,0l.935.541c.077.047.077.13-.01.18Z" transform="translate(-387.757 -68.372)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19320" data-name="Path 19320" d="M425.43,84.092a.363.363,0,0,1-.309,0l-.935-.541c-.085-.052-.077-.127.01-.18l.932-.536a.326.326,0,0,1,.307,0l.932.541c.082.047.08.127-.005.18Z" transform="translate(-386.904 -67.875)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19321" data-name="Path 19321" d="M421.454,81.781a.349.349,0,0,1-.307,0l-.935-.544c-.077-.045-.077-.127.01-.177l.93-.541a.372.372,0,0,1,.309,0l.932.546c.077.045.077.127-.008.177Z" transform="translate(-392.832 -71.322)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19322" data-name="Path 19322" d="M420.882,81.449a.333.333,0,0,1-.307,0l-.935-.544c-.08-.045-.08-.127.007-.177l.935-.539a.333.333,0,0,1,.307,0l.935.544c.085.05.077.127-.01.177Z" transform="translate(-393.689 -71.82)" fill="#484d4b" fillRule="evenodd" />
                      <path id="Path_19323" data-name="Path 19323" d="M419.166,80.453a.349.349,0,0,1-.307,0l-.932-.544c-.087-.05-.08-.127.007-.18l.932-.536a.326.326,0,0,1,.307-.005l.935.541c.077.047.077.13-.01.18Z" transform="translate(-396.252 -73.309)" fill="#484d4b" fillRule="evenodd" />
                    </g>
                    <path id="Path_19324" data-name="Path 19324" d="M430.729,76.637,448.68,87.07l-10.236,5.909L420.493,82.547Z" transform="translate(-384.973 -67.828)" fill="#787878" fillRule="evenodd" />
                  </g>
                  <g id="Group_27469" data-name="Group 27469">
                    <path id="Path_19325" data-name="Path 19325" d="M405.017,68.295c.005-1.189.858-1.675,1.892-1.077l44.8,25.864a4.171,4.171,0,0,1,1.88,3.256l-.1,35.146c0,1.2-.858,1.675-1.892,1.077l-44.8-25.866a4.147,4.147,0,0,1-1.88-3.254Z" transform="translate(-404.917 -65.528)" fill="#c2c2c0" fillRule="evenodd" />
                    <path id="Path_19326" data-name="Path 19326" d="M424.729,114.75l.1-35.146,2.466-1.436-.1,35.148a1.949,1.949,0,0,1-.04.411,1.116,1.116,0,0,1-.511.8l-2.466,1.434A1.312,1.312,0,0,0,424.729,114.75Z" transform="translate(-376.158 -48.795)" fill="#8a8c8b" fillRule="evenodd" />
                    <path id="Path_19327" data-name="Path 19327" d="M405.179,67.949l2.466-1.434.01-.005a.7.7,0,0,1,.1-.047.682.682,0,0,1,.1-.037l.065-.015.015,0,.027,0a.845.845,0,0,1,.115-.015,1.043,1.043,0,0,1,.125,0l.037,0,.027,0,.075.01a1.053,1.053,0,0,1,.167.035l.067.022.067.022c.027.012.055.017.085.03a2.291,2.291,0,0,1,.259.13l44.8,25.864a3.1,3.1,0,0,1,.566.441c.02.017.04.027.06.045l.075.08.062.065c.02.025.045.047.07.075.05.057.1.122.15.187.02.022.037.047.055.07l.027.037a.574.574,0,0,1,.045.062q.06.082.112.165c.035.052.067.107.1.162l.007.012.007.015a1.378,1.378,0,0,1,.075.132c.027.052.057.107.082.162l.067.142,0,.01.005.012c.025.057.047.115.07.17s.045.12.065.18l.025.085.013.037.02.07c.02.072.04.142.052.214.01.045.015.085.022.127l.012.067.01.065a2.516,2.516,0,0,1,.025.332v.032L453.2,97.2a2.6,2.6,0,0,0-.037-.431l-.01-.067a3.293,3.293,0,0,0-.1-.411l-.01-.037c-.047-.15-.1-.3-.165-.444l-.005-.012c-.067-.15-.142-.294-.222-.439l-.007-.012a4.3,4.3,0,0,0-.264-.4l-.025-.037c-.087-.117-.182-.227-.277-.332a.75.75,0,0,0-.062-.065c-.042-.047-.087-.085-.132-.127a3.2,3.2,0,0,0-.566-.439l-44.8-25.864a2.485,2.485,0,0,0-.347-.16l-.065-.022a1.631,1.631,0,0,0-.309-.065l-.027,0a1.105,1.105,0,0,0-.3.017l-.015,0a.8.8,0,0,0-.272.1l.005,0Z" transform="translate(-404.526 -66.386)" fill="#8a8c8b" fillRule="evenodd" />
                  </g>
                </g>
                <g id="Group_27471" data-name="Group 27471" transform="translate(490.28 23.8)">
                  <path id="Path_19328" data-name="Path 19328" d="M453.66,34.412c-.379,2.466-2.5,7.916-.421,14.076-5.984,4.956-11.192,3.091-12.618-.207a25.733,25.733,0,0,0-.429-9.778Z" transform="translate(-425.143 -7.954)" fill="#f0c17d" fillRule="evenodd" />
                  <path id="Path_19329" data-name="Path 19329" d="M435,28.5c-2.229,9.063,1.763,13.234,3.276,19.387a16.3,16.3,0,0,0,3.992,7.038c1.969,2.224,3.66,3.024,5.348,2.939,3.234-.167,13.455-9.905,12.9-14.221l-2.147-16.709Z" transform="translate(-433.889 -19.115)" fill="#ffdba7" fillRule="evenodd" />
                  <path id="Path_19330" data-name="Path 19330" d="M455.538,47.852c-1.017-3.513.721-4.475,1.608-4.555,3.617-.324,1.763,6,.992,8.826-1.942,3.538-1.256,9.362-1.947,12.934,10.917-6.574,14.094-10.2,7.946-28.247-3.363-9.87-11.778-17.1-23.852-10.419-2.735,1.289-6.188,4.121-6.128,8.935C434.279,45.242,450.192,41.736,455.538,47.852Z" transform="translate(-434.156 -23.8)" fill="#632404" fillRule="evenodd" />
                </g>
              </g>
            </g>
            <g id="Button" transform="translate(1180 5640)">
              <rect id="Rounded_Rectangle_14" data-name="Rounded Rectangle 14" width={146} height={49} rx="24.5" transform="translate(-137 -4)" fill="#4b9aff" />
              <text id="Withdraw" transform="translate(-103 26)" fill="#fff" fontSize={16} fontFamily="AvenirNext-Bold, Avenir Next" fontWeight={700}><tspan x={0} y={0}>Withdraw</tspan></text>
            </g>
            <g id="Dot">
              <circle id="Ellipse_134-2" data-name="Ellipse 134" cx="7.5" cy="7.5" r="7.5" transform="translate(712 4571)" fill="#4b9aff" />
              <circle id="Ellipse_1363" data-name="Ellipse 1363" cx="7.5" cy="7.5" r="7.5" transform="translate(712 5113)" fill="#4b9aff" />
              <circle id="Ellipse_1436" data-name="Ellipse 1436" cx="7.5" cy="7.5" r="7.5" transform="translate(712 4808)" fill="#4b9aff" />
              <circle id="Ellipse_1364" data-name="Ellipse 1364" cx="7.5" cy="7.5" r="7.5" transform="translate(525 5292)" fill="#4b9aff" />
              <circle id="Ellipse_1365" data-name="Ellipse 1365" cx="7.5" cy="7.5" r="7.5" transform="translate(862 5946)" fill="#4b9aff" />
              <circle id="Ellipse_1366" data-name="Ellipse 1366" cx="7.5" cy="7.5" r="7.5" transform="translate(1416 5422)" fill="#ff6464" />
              <circle id="Ellipse_1367" data-name="Ellipse 1367" cx="7.5" cy="7.5" r="7.5" transform="translate(1271 4904)" fill="#ff6464" />
              <circle id="Ellipse_1437" data-name="Ellipse 1437" cx="7.5" cy="7.5" r="7.5" transform="translate(1271 4985)" fill="#ff6464" />
              <circle id="Ellipse_1368" data-name="Ellipse 1368" cx="7.5" cy="7.5" r="7.5" transform="translate(1187 4725)" fill="#ff6464" />
            </g>
          </g>
        </svg>
              )}
          { isMobile && (
          <StaticImage src="https://coinsclone.mo.cloudinary.net/images/workflow/p2p-mobile.webp" 
              alt="p2p-crypto-exchange-workflow"
              width={392} />
              )}
        
      </div>
    </section>
    </div>
  )
}

export default HowOur