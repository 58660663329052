import React, { useState,useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Addon = () => {

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };
      
    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
    }, []);


  const [activeTab, setActiveTab] = useState('tabs1');

  const openTabSection = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <section className="trading addons bgremove pt-100 mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          <h3 className="heading-h2">
            <span className="heading-h3">
              <span className="bluecolor">Add-on modules</span> of our
            </span>
            P2P Crypto Exchange Software
          </h3>
        </div>
        <div className="row">
          <div className="courses-details-desc">
            <ul className="nav nav-tabs tabNav nav-pills" id="myTab" role="presentation">
              <li
                role="presentation"
                className={activeTab === 'tabs1' ? 'current' : ''}
                onClick={() => openTabSection('tabs1')}
              >
                Atomic Swap
              </li>
              <li
                role="presentation"
                className={activeTab === 'tabs2' ? 'current' : ''}
                onClick={() => openTabSection('tabs2')}
              >
                Staking module
              </li>
              <li
                role="presentation"
                className={activeTab === 'tabs3' ? 'current' : ''}
                onClick={() => openTabSection('tabs3')}
              >
                Preferred Trading selection
              </li>
              <li
                role="presentation"
                className={activeTab === 'tabs4' ? 'current' : ''}
                onClick={() => openTabSection('tabs4')}
              >
                Two-factor Authentication
              </li>
            </ul>
            <div className="tab-content">
              <div id="tabs1" className={`tab-pane tabs_item ${activeTab === 'tabs1' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tabs1' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <h4 className="heading-h4">Atomic Swap</h4>
                    <p className="pharagraph">By identifying trusted users' transactions, P2P swaps do away with the requirement for a central authority.</p>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/atomic-swap.png"
                      alt="Atomic Swap"
                      width={400}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tabs2" className={`tab-pane tabs_item ${activeTab === 'tabs2' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tabs2' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <h4 className="heading-h4">Staking module</h4>
                    <p className="pharagraph">It allows users to hold cryptocurrencies and also improves control, security, and liquidity.</p>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/wallet-integration.png"
                      alt="Staking module"
                      width={400}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tabs3" className={`tab-pane tabs_item ${activeTab === 'tabs3' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tabs3' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <h4 className="heading-h4">Preferred Trading selection</h4>
                    <p className="pharagraph">For a more secure and reliable trading experience, buyers can freely select their preferred vendors and vice versa.</p>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/staking-module.png"
                      alt="Preferred Trading selection"
                      width={303}
                    />
                  </div>
                  )}
                </div>
              </div>

              <div id="tabs4" className={`tab-pane tabs_item ${activeTab === 'tabs4' ? 'fadeIn animated' : ''}`} style={{ display: activeTab === 'tabs4' ? 'block' : 'none' }}>
                <div className="row table-content orderflex">
                  <div className="col-md-6 order2">
                    <h4 className="heading-h4">Two-factor Authentication</h4>
                    <p className="pharagraph">It guarantees access to a user's account and prevents the invention of fraudulent activity in a Peer-to-Peer crypto exchange platform.</p>
                  </div>
                  { isDesktop && (
                  <div className="col-md-6 text-center order1">
                    <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/p2p-new/escrow-system.png"
                      alt="Two-factor Authentication"
                      width={350}
                    />
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Addon;
