import React,{useState,useEffect} from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'


const WhyShould =()=> {

  const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
      const updateMedia = () => {
        setIsDesktop(window.innerWidth > 768);
      };

      window.addEventListener("resize", updateMedia);
      updateMedia(); // Check initial size

      return () => window.removeEventListener("resize", updateMedia);
    }, []);

    return (
      <section className="whyshould mb-0 pt-70">
        <div className="container">
              <h2 className="heading-h2 text-center"><span className="bluecolor">White Label P2P</span>  Crypto Exchange Software</h2>
          <div className="row table-content mw1140 mx-auto">
            { isDesktop && (
            <div className="col-lg-6 col-md-12  text-center" >
            <StaticImage
                src="https://coinsclone.mo.cloudinary.net/images/p2p-new/why-should-you-start-p2-p-crypto-exchange.png"
                alt="Why Should Start P2P Crypto Exchange"
                width={450}
              />
            </div>
            )}
            <div className="col-lg-6 col-md-12">
              <p className='pharagraph'>
              Our White Label P2P Crypto Exchange software has remarkable UI/UX features that meet the demands of the current Crypto market. The P2P crypto exchange software we offer has everything in place for user-to-user trading and you can become a successful entrepreneur within a short period. Launch a futuristic and innovative White-Label P2P Exchange specially made for businesses to thrive and accelerate their revenue. 
              </p>
              <div className="banner-btn mt-4">
                <ButtonComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}

export default WhyShould;